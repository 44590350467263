import { createStore, applyMiddleware } from "redux";
import rootReducer from "../redux/Reducers/index";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
// console.log("l",process.env.NODE_ENV)

// const pReducer = persistReducer(persistConfig, rootReducer);

const middleware = [thunk, logger];
const composeEnacher = composeWithDevTools({ trace: true, traceLimit: 25 });
export const store = createStore(
  rootReducer,
  composeEnacher(applyMiddleware(...middleware))
);
// export let persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch;
  
export type RootState = ReturnType<typeof rootReducer>
// export default store;
