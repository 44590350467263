import axios from 'axios';

// const BASE_URL: string = 'https://61c6-157-47-229-155.ngrok.io/api/v1';

// const BASE_URL: string = 'http://54.87.140.85:5000/api/v1'; // live instance
const BASE_URL: string = 'https://www.approveme.biz/api/api/v1'; // live instance

// const BASE_URL: string = 'http://localhost:5000/api/v1'; // live instance
// const BASE_URL: string = 'http://3.83.17.231:5000/api/v1'; // staging instance//
const axiosInstance = axios.create({ baseURL: BASE_URL });

const errHandling = (error: any) => Promise.reject(error);

const reqHandling = (config: any) => {
    const obj = { ...config };
    const token = localStorage.getItem('token');
    // console.log("TOKEN SERVICE", token);
    if (token) obj.headers['Authorization'] = token;
    return obj;
};

const resHandling = (response: any) => response;
axiosInstance.interceptors.request.use(reqHandling, errHandling);
axiosInstance.interceptors.response.use(resHandling, errHandling);

export default axiosInstance;
