const ROUTES: any = {
    PersonalInformation: '/pi/:role',
    IncomeInformation: '/incomeInformation/:role',
    EmploymentInformation: '/employmentInformation/:role',
    AddressInformation: '/addressInformation/:role',
    SuccessPage: '/page/success',
    WelcomePage: '/page/welcome',
    Dashboard: '/vendoradmin/dashboard',
    Login: '/vendoradmin',
    ForgetPassword: '/vendoradmin/forgetPassword',
    ResetPassword: '/vendoradmin/reset-password',
    ApplicantDetails: '/vendoradmin/userDetails/:id',
    ApplicantRequest: '/vendoradmin/userRequest/:id',
    ApplicantTransactionData: '/vendoradmin/userTransactionData/:id',
    EditVendor: '/vendoradmin/vendor/edit',
};

const APPLICANT_ROUTES: any = {
    PersonalInformation: '/pi/applicant?_vendorID=',
    IncomeInformation: '/incomeInformation/applicant?_vendorID=',
    EmploymentInformation: '/employmentInformation/applicant?_vendorID=',
    AddressInformation: '/addressInformation/applicant?_vendorID=',
    SuccessPage: '/page/success?_vendorID=',
    WelcomePage: '/page/welcome?_vendorID=',
};
const CO_APPLICANT_ROUTES: any = {
    PersonalInformation: '/pi/co-applicant?_vendorID=',
    IncomeInformation: '/incomeInformation/co-applicant?_vendorID=',
    EmploymentInformation: '/employmentInformation/co-applicant?_vendorID=',
    AddressInformation: '/addressInformation/co-applicant?_vendorID=',
};

export { ROUTES, APPLICANT_ROUTES, CO_APPLICANT_ROUTES };
