import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/Reducers/index';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { APPLICANT_ROUTES, CO_APPLICANT_ROUTES, ROUTES } from '../../services/applicationConstants';
import { withRouter } from 'react-router-dom';
import { _getVendorId } from '../../services';

import './style.css';

function Index(props: any) {
    const _vendorID = _getVendorId(props);
    console.log('_vendorID', _vendorID);
    const [open, setOpen] = React.useState(false);

    const isCoApplicant: number[] = useSelector((state: RootState) => {
        return state.FinanceFormReducer.isCoApplicant;
    });
    const applicantSteps: number[] = useSelector((state: RootState) => {
        return state.FinanceFormReducer.applicantSteps;
    });
    const coApplicantSteps: number[] = useSelector((state: RootState) => {
        return state.FinanceFormReducer.coApplicantSteps;
    });

    console.log('pppppppprops.route.params', props);
    return (
        <div className={open ? 'openside sidenav' : 'notopenside sidenav'}>
            <button
                className="toggleNav MenuIcon"
                onClick={() => {
                    setOpen(!open);
                }}
            >
                <MenuIcon />
            </button>
            <button
                className="toggleNav CloseIcon"
                onClick={() => {
                    setOpen(!open);
                }}
            >
                <CloseIcon />
            </button>
            <div className="sideInNav">
                <div className={open ? 'open' : 'notopen'}>
                    <h2 className="siteNameNav">Applicant Details</h2>
                    <nav>
                        <ul className="navList">
                            <li>
                                <Link
                                    to={
                                        isCoApplicant
                                            ? CO_APPLICANT_ROUTES.PersonalInformation + _vendorID
                                            : APPLICANT_ROUTES.PersonalInformation + _vendorID
                                    }
                                    className={
                                        isCoApplicant && applicantSteps.includes(1)
                                            ? ''
                                            : !isCoApplicant && applicantSteps.includes(1)
                                            ? ''
                                            : 'disableLink'
                                    }
                                    style={
                                        props.location.pathname.includes('personalInformation')
                                            ? {
                                                  background: 'white',
                                                  color: 'black',
                                                  marginLeft: '10px !important',
                                                  borderTopLeftRadius: '40px',
                                                  borderBottomLeftRadius: '40px',
                                              }
                                            : {}
                                    }
                                >
                                    Personal <br />
                                    Information
                                    {/* <button>2</button> */}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={
                                        isCoApplicant
                                            ? CO_APPLICANT_ROUTES.AddressInformation + _vendorID
                                            : APPLICANT_ROUTES.AddressInformation + _vendorID
                                    }
                                    className={
                                        isCoApplicant && applicantSteps.includes(2)
                                            ? ''
                                            : !isCoApplicant && applicantSteps.includes(2)
                                            ? ''
                                            : 'disableLink'
                                    }
                                    style={
                                        props.location.pathname.includes('addressInformation')
                                            ? {
                                                  background: 'white',
                                                  color: 'black',
                                                  marginLeft: '10px !important',
                                                  borderTopLeftRadius: '40px',
                                                  borderBottomLeftRadius: '40px',
                                              }
                                            : {}
                                    }
                                >
                                    Address <br />
                                    Information
                                    {/* <button>3</button> */}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={
                                        isCoApplicant
                                            ? CO_APPLICANT_ROUTES.EmploymentInformation + _vendorID
                                            : APPLICANT_ROUTES.EmploymentInformation + _vendorID
                                    }
                                    className={
                                        isCoApplicant && applicantSteps.includes(3)
                                            ? ''
                                            : !isCoApplicant && applicantSteps.includes(3)
                                            ? ''
                                            : 'disableLink'
                                    }
                                    style={
                                        props.location.pathname.includes('employmentInformation')
                                            ? {
                                                  background: 'white',
                                                  color: 'black',
                                                  marginLeft: '10px !important',
                                                  borderTopLeftRadius: '40px',
                                                  borderBottomLeftRadius: '40px',
                                              }
                                            : {}
                                    }
                                >
                                    Employment <br />
                                    Information
                                    {/* <button>4</button> */}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={
                                        isCoApplicant
                                            ? CO_APPLICANT_ROUTES.IncomeInformation + _vendorID
                                            : APPLICANT_ROUTES.IncomeInformation + _vendorID
                                    }
                                    className={
                                        isCoApplicant && applicantSteps.includes(4)
                                            ? ''
                                            : !isCoApplicant && applicantSteps.includes(4)
                                            ? ''
                                            : 'disableLink'
                                    }
                                    style={
                                        props.location.pathname.includes('incomeInformation')
                                            ? {
                                                  background: 'white',
                                                  color: 'black',
                                                  marginLeft: '10px !important',
                                                  borderTopLeftRadius: '40px',
                                                  borderBottomLeftRadius: '40px',
                                              }
                                            : {}
                                    }
                                >
                                    Income <br />
                                    Information
                                    {/* <button>5</button> */}
                                </Link>
                            </li>
                            {/* <li style={{ marginTop: 30 }}>
                                <Link to={ROUTES.Login}>Admin</Link>
                            </li> */}
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
}
export default withRouter(Index);
