import qs from 'qs';

export function _getVendorId({ location }: any) {
    const { _vendorID } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
    });
    return _vendorID;
}

export function _getApplicationNum({ location }: any) {
    const { applicationNum } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
    });
    return applicationNum;
}

export function validateUrl(str: any) {
    var tarea = str;
    console.log('outside //', tarea);
    if (tarea.indexOf('http://') == -1 && tarea.indexOf('https://') == -1) {
        // do something here
        console.log('no //');
        return `//${tarea}`;
    } else return tarea;
}
