function containSpecialCharacter(str:any) {
    var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;                      
return format.test(str);
  }
export const checkValidator = (formData: any) => {
    let isFormValid: boolean = true;
    let error: any = {
        address: '',
        city: '',
        state: '',
        zip: '',
        housingPayment: '',
        taaMonths: '',
        taaYears: '',
        ownrent: '',
        prevAddress:"",
        prevCity:"",
       
    };
    console.log('formData', formData);

    if (
        formData &&
        (formData?.address == undefined || formData?.address == '' || formData?.address.trim().length == 0)
    ) {
        isFormValid = false;
        error.address = 'This Field cannot be empty!';
    }
    else if(containSpecialCharacter(formData?.address)){
        isFormValid = false;
        error.address = 'Please remove special characters';
    }
    if(containSpecialCharacter(formData?.prevAddress)){
        isFormValid = false;
        error.prevAddress = 'Please remove special characters';
    }
    if (formData && (formData?.city == undefined || formData?.city == '' || formData?.city.trim().length == 0)) {
        isFormValid = false;
        error.city = 'This Field cannot be empty!';
    }else if(containSpecialCharacter(formData?.city)){
        isFormValid = false;
        error.city = 'Please remove special characters';
    }
    if(containSpecialCharacter(formData?.prevCity)){
        isFormValid = false;
        error.prevCity = 'Please remove special characters';
    }

    if (formData && (formData?.state == undefined || formData?.state == '' || formData?.state.trim().length == 0)) {
        isFormValid = false;
        error.state = 'This Field cannot be empty!';
    }
    if (formData && (formData?.zip == undefined || formData?.zip == '' || formData?.zip.trim().length == 0)) {
        isFormValid = false;
        error.zip = 'This Field cannot be empty!';
    }
    if (formData && formData?.zip && (formData?.zip.length > 7 || formData?.zip.length < 0)) {
        isFormValid = false;
        error.zip = 'Zip code must be less than 7 digits.';
    }
    if (
        formData &&
        formData?.ownrent != 'Own' &&
        ((formData?.housingPayment && formData?.housingPayment.trim().length > 10) ||
            formData?.housingPayment == '' ||
            formData?.housingPayment == undefined)
    ) {
        isFormValid = false;
        error.housingPayment = 'Please provide 10 digit amount';
    }

    if (formData && formData?.ownrent == undefined) {
        isFormValid = false;
        error.ownrent = 'Please choose an option';
    }

    if (formData && (Number(formData?.taaYears) >= 100 || Number(formData?.taaYears) < 0)) {
        isFormValid = false;
        error.taaYears = 'Year should be less than 100.';
    } else if (formData && (formData?.taaYears == '' || formData?.taaYears == undefined)) {
        isFormValid = false;
        error.taaYears = 'This Field cannot be empty!';
    }
    if (formData && formData?.taaMonths && (Number(formData?.taaMonths) > 11 || Number(formData?.taaMonths) < 0)) {
        isFormValid = false;
        error.taaMonths = 'Months should be less than 12.';
    } else if (formData && (formData?.taaMonths == '' || formData?.taaMonths == undefined)) {
        isFormValid = false;
        error.taaMonths = 'This Field cannot be empty!';
    }

    if (
        formData &&
        formData?.prevtaaYears &&
        (Number(formData?.prevtaaYears) >= 100 || Number(formData?.prevtaaYears) < 0)
    ) {
        isFormValid = false;
        error.prevtaaYears = 'Year should be less than 100.';
    }
    if (
        formData &&
        formData?.prevtaaMonths &&
        (Number(formData?.prevtaaMonths) > 11 || Number(formData?.prevtaaMonths) < 0)
    ) {
        isFormValid = false;
        error.prevtaaMonths = 'Months should be less than 12.';
    }

    //   if (formData.acceptTnC !== undefined) {
    //     isFormValid = false;
    //     error.acceptTnC = "This field cannot be empty!";
    //   } else if (formData.acceptTnC === false) {
    //     isFormValid = false;
    //     error.acceptTnC = "Please accept Terms and Conditions";
    //   }
    console.log('Number(formData?.taaYears)', Number(formData?.taaYears), formData?.taaYears);

    return {
        isFormValid,
        error,
    };
};
