import { useEffect } from 'react';
import { IS_USER_LOGIN } from '../redux/types';
import { useDispatch } from 'react-redux';
export function useLoginCheck(callback: any) {
    const dispatch = useDispatch();
    useEffect(() => {
        if (localStorage.getItem('token') && localStorage.getItem('userData')) {
            dispatch(callback);
            dispatch({ type: IS_USER_LOGIN, payload: true });
        } else {
            dispatch({ type: IS_USER_LOGIN, payload: false });
        }
    }, []);
}
