/* eslint-disable no-mixed-operators */
import { useState } from 'react';
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import './styles.css';

interface DProps {
    data: any;
    label: string;
    handleChange: any;
    value: any;
    name: string;
    required: boolean;
}

function DropDown({ data, label, handleChange, value, name, required }: DProps) {
    // eslint-disable-next-line no-undef
    // const classes = useStyles();
    // const [age, setAge] = React.useState('');

    // const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    //     setAge(event.target.value as string);
    // };
    return (
        <>
            <InputLabel id="label" className="select-drop-down-label">
                {label}
                {required ? <sup>*</sup> : null}
            </InputLabel>
            <Select
                className="select-drop-down"
                labelId="label"
                id="select"
                name={name}
                value={value}
                onChange={handleChange}
            >
                {data &&
                    data.map((obj: any, i: number) => {
                        return <MenuItem value={obj.value}>{obj.value}</MenuItem>;
                    })}
            </Select>
        </>
    );
}

export default DropDown;
