import React, { useState } from "react";
import "../index.css";
import Table from "../../../components/Table/Table";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { getTransactionData, getUserDetails, getUserRequest } from "../../../redux/Actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";

export default function Index(props: any) {
  const rowLimit: number = 10;
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(1);
  const [category, setCategory] = useState<string>("ALL");

  const userTransactionData = useSelector((state: any) => {
    return state.AuthReducer.userTransactionData;
  });
  console.log("user requestttttttttttt",userTransactionData)
  React.useEffect(() => {
    dispatch(getTransactionData(props.match.params.id));
  }, []);

  return (
    <div className="mainAdminDiv">
      <div className="borderDiv">
        <h3>User Request Details</h3>
        <div className="dashbrdPage">
          <h4> Applicant Data </h4>
        {userTransactionData?.applicantData && Object.keys(userTransactionData?.applicantData).map((data,i)=>{
       return   <p>{data}: {Object.values(userTransactionData?.applicantData)[i]}</p>
        })}
        </div>
        {userTransactionData?.co_applicantData &&   <div className="dashbrdPage">
          <h4>Co-Applicant Data </h4>
        {userTransactionData?.co_applicantData && Object.keys(userTransactionData?.co_applicantData).map((data,i)=>{
       return   <p>{data}: {Object.values(userTransactionData?.co_applicantData)[i]}</p>
        })}
        </div>}
      </div>
    </div>
  );
}
