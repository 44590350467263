import { APPLICANT_DATA, GET_VENDOR_DETAILS, IS_USER_LOGIN, LOGIN_DATA, USER_DETAILS, USER_REQUEST, USER_TRANSACTION } from '../types';

export const initialState = {
    user: '',
    isUserLogin: false,
    applicantData: [],
    userDetails: [],
    userRequest:[],
    userTransactionData:[],
    vendorDetails: {},
    
};

export const AuthReducer = (state = initialState, action: any) => {
    
    switch (action.type) {
        case LOGIN_DATA:
            let { data } = action.payload;
            console.log('setting userdata', data, action.payload);
            return {
                ...state,
                user: data,
            };
        case IS_USER_LOGIN:
            return {
                ...state,
                isUserLogin: action.payload,
            };

        case 'LOGOUT_USER':
            localStorage.clear();
            return {
                isUserLogin: false,
                user: '',
            };

        case APPLICANT_DATA:
            return {
                ...state,
                applicantData: action.payload,
            };
        case USER_DETAILS:
            return {
                ...state,
                userDetails: action.payload,
            };
            case USER_REQUEST:
                return {
                    ...state,
                    userRequest: action.payload,
                };
                case USER_TRANSACTION:
                return {
                    ...state,
                    userTransactionData: action.payload,
                };
        case GET_VENDOR_DETAILS:
            return {
                ...state,
                vendorDetails: action.payload.data,
            };
        default:
            return state;
    }
};
