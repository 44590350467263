import { useHistory } from 'react-router-dom';
import { APPLICANT_ROUTES } from '../../services';
import axios from '../../services/axiosService';
import { validateUrl } from '../../services/helperFunctionServices';
import { toast } from '../../utils/toast';

export const loanInformation = (body: any, step: number) => async (dispatch: any) => {
    dispatch({ type: 'UPDATE_LOAN_FORM_DATA', payload: body, step });
};

export const applicantInformation = (body: any, step: number) => async (dispatch: any) => {
    dispatch({ type: 'APPLICANT_LOAN_FORM_DATA', payload: body, step: step });
};

export const coApplicantInformation = (body: any, step: number) => async (dispatch: any) => {
    return new Promise(async (resolve: any, reject: any) => {
        await dispatch({
            type: 'CO_APPLICANT_LOAN_FORM_DATA',
            payload: body,
            step: step,
        });

        resolve();
    });
};

export const uploadFormData = (formdata: any, vendorInfo: any, props: any) => async (dispatch: any) => {
    console.log('in upload form data ', { formdata, vendorInfo, props });

    try {
        const res = await axios.post('/specialFinanceForm/postSpecialFinance', formdata);
        console.log('uploadFormData res', res.data);

        if (
            res &&
            res.data &&
            res.data.code == 200 &&
            vendorInfo.websiteUniqueUrl &&
            vendorInfo.websiteUniqueUrl != null &&
            vendorInfo.enableWebsiteUrl === 1
        ) {
            return window.open(validateUrl(vendorInfo.websiteUniqueUrl), '_self');
        } else if (res && res.data && res.data.code == 200 && res.data.data && res.data.data.applicationNum) {
            return props.history.push(
                APPLICANT_ROUTES.SuccessPage +
                    formdata._vendorID +
                    '&applicationNum=' +
                    btoa(res.data.data.applicationNum),
            );
        } else {
            return props.history.push(APPLICANT_ROUTES.SuccessPage + formdata?._vendorID);
        }

        // return true;
    } catch (err) {
        console.log('uploadFormData Err', err);
        // return false;
        return props.history.push(APPLICANT_ROUTES.SuccessPage + formdata?._vendorID);
    }
};
export const _getVendorDetails = (_venderID: any) => async (dispatch: any) => {
    try {
        const res = await axios.get(`/specialFinanceForm/vendorDetails/${_venderID}`);
        console.log('_getVendorDetails', res.data);
        let { data } = res.data;
        if (res.data.code === 200) {
            dispatch({
                type: 'VENDOR_INFO',
                payload: data,
            });
        } else {
            toast.error('Please check your vendor id');
        }
    } catch (err) {
        console.log('uploadFormData Err', err);
        toast.error('Please check your vendor id');
    }
};

export const _getApplicationStatusApi = (applicationNum: any) => async (dispatch: any) => {
    try {
        const res = await axios.get(`/specialFinanceForm/applicationStatus/${applicationNum}`);
        console.log('_getApplicationStatus', res.data);
        if (res.data.code === 200) {
            return res.data.data;
        } else {
            return false;
        }
    } catch (err) {
        console.log('uploadFormData Err', err);
        // toast.error('Please check your vendor id');
        return false;
    }
};
