import { useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { ROUTES } from '../../services';
import { APPLICANT_ROUTES } from '../../services/applicationConstants';
import './Header.css';

function Header(props: any) {
    console.log('props', props);
    const vendorInfo = useSelector((state: any) => {
        return state.FinanceFormReducer.vendorInfo;
    });
    return (
        <div className="header">
            {props.location.pathname.includes('vendoradmin') ? (
                ''
            ) : (
                <p className="title">
                    <Link
                        style={{ textDecoration: 'none' }}
                        to={`${APPLICANT_ROUTES.PersonalInformation}${vendorInfo._vendorID}`}
                    >
                        Dashboard
                    </Link>
                </p>
            )}

            <p>
                {props.location.pathname.includes('vendoradmin') ? (
                    'ELEVATE'
                ) : (
                    <Link
                        style={{ textDecoration: 'none' }}
                        to={`${APPLICANT_ROUTES.PersonalInformation}${vendorInfo._vendorID}`}
                    >
                        Loan Application
                    </Link>
                )}
            </p>

            <p className="companyName">
                {vendorInfo?.companyName
                    ? vendorInfo?.companyName
                    : props.location.pathname === ROUTES.Login || props.location.pathname === ROUTES.ForgetPassword
                    ? ''
                    : 'Please check your vendor id'}
            </p>
        </div>
    );
}

export default withRouter(Header);
