import { useEffect, useState } from 'react';

import './style.css';
// import Logoimg from "../../img/logo.png";
import { validateVendorUpdate } from './Validate';
// import { LoginIProps } from "../../services/interfaces";
import { getVendorDetails, updateVendorDetails } from '../../redux/Actions/AuthAction';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { FileCopy } from '@material-ui/icons';

interface IUpdateProps {
    email: string | undefined;
    firstName: string | undefined;
    lastName?: string | undefined;
    companyName: string | undefined;
    vendorApiSfcCompanyCode: string | undefined;
    websiteUniqueUrl?: string | undefined;
}
interface IValidateForm {
    isFormValid: boolean;
    error: IUpdateProps;
}
export default function EditVendor(props: any) {
    const dispatch = useDispatch();
    const [state, setState] = useState<IUpdateProps | undefined>(undefined);
    const [error, setError] = useState<IUpdateProps | null>(null);
    const { vendorRegisteredData, vendorDetails, user } = useSelector((state: any) => {
        return state.AuthReducer;
    });

    useEffect(() => {
        dispatch(getVendorDetails({ userId: user?.userId }));
    }, []);

    useEffect(() => {
        setState(vendorDetails);
    }, [vendorDetails]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        let response: IValidateForm = await validateVendorUpdate(state);

        if (response.isFormValid) {
            await dispatch(updateVendorDetails({ userId: vendorDetails.userId, payload: state }));

            // setState({
            //   email: "",
            //   lastName: "",
            //   firstName: "",
            //   companyName: "",
            //   vendorApiSfcCompanyCode: "",
            // });

            setError({
                email: '',
                lastName: '',
                firstName: '',
                companyName: '',
                vendorApiSfcCompanyCode: '',
                websiteUniqueUrl: '',
            });
        } else {
            setError(response.error);
        }
        // console.log("response", response, state);
    };

    const onHandleChange = (event: any) => {
        const { name, value } = event.target;
        setState((prevState: any) => ({ ...prevState, [name]: value }));
    };

    return (
        <div className="commnbg1">
            <div className="formwidth signupform loginform">
                <h3> Edit Vendor</h3>
                <form>
                    <div className="formItem half">
                        <div className="form-group">
                            <label className="input__label">
                                First Name<sup>*</sup>
                                <input
                                    className="inptField"
                                    name="firstName"
                                    placeholder="First Name"
                                    onChange={onHandleChange}
                                    value={state?.firstName}
                                ></input>
                            </label>
                            {error?.firstName && <p>{error?.firstName}</p>}
                        </div>
                        {/* {error?.fname && <p>{error?.fname}</p>} */}

                        <div className="form-group">
                            <label className="input__label">
                                Last Name
                                <input
                                    className="inptField"
                                    name="lastName"
                                    value={state?.lastName}
                                    placeholder="Last Name"
                                    onChange={onHandleChange}
                                ></input>
                            </label>
                        </div>
                        {/* {error?.lname && <p>{error?.lname}</p>} */}
                    </div>
                    <div className="formItem half">
                        <div className="form-group">
                            <label className="input__label">
                                Email<sup>*</sup>
                                <input
                                    className="inptField"
                                    name="email"
                                    placeholder="Email"
                                    value={state?.email}
                                    onChange={onHandleChange}
                                ></input>
                            </label>
                            {error?.email && <p>{error?.email}</p>}
                        </div>
                        {/* {error?.email && <p>{error?.email}</p>} */}

                        <div className="form-group">
                            <label className="input__label">
                                Vendor Name<sup>*</sup>
                                <input
                                    name="companyName"
                                    className="inptField"
                                    type="text"
                                    placeholder="Vendor Name"
                                    onChange={onHandleChange}
                                    value={state?.companyName}
                                ></input>
                            </label>
                            {error?.companyName && <p>{error?.companyName}</p>}
                        </div>
                    </div>

                    <div className="formItem">
                        <div className="form-group">
                            <label className="input__label">
                                Vendor API SFC Company Code<sup>*</sup>
                                <input
                                    name="vendorApiSfcCompanyCode"
                                    className="inptField"
                                    type="text"
                                    placeholder="SFC Company Code"
                                    value={state?.vendorApiSfcCompanyCode}
                                    onChange={onHandleChange}
                                ></input>
                            </label>
                            {error?.vendorApiSfcCompanyCode && <p>{error?.vendorApiSfcCompanyCode}</p>}
                        </div>
                    </div>

                    <div className="formItem">
                        <div className="form-group">
                            <label className="input__label">
                                Website URL
                                <input
                                    name="websiteUniqueUrl"
                                    className="inptField"
                                    type="text"
                                    placeholder="Website URL"
                                    value={state?.websiteUniqueUrl}
                                    onChange={onHandleChange}
                                ></input>
                            </label>
                            {/* {error?.vendorApiSfcCompanyCode && <p>{error?.vendorApiSfcCompanyCode}</p>} */}
                        </div>
                    </div>
                    <button className="submitBtn" onClick={handleSubmit}>
                        Edit Vendor{' '}
                    </button>
                </form>
            </div>
        </div>
    );
}
