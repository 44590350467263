import React, { useState } from "react";
import "../index.css";
import xml2js from 'xml2js';
import Table from "../../../components/Table/Table";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { getUserDetails } from "../../../redux/Actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";

export default function Index(props: any) {
  const rowLimit: number = 10;
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(1);
  const [category, setCategory] = useState<string>("ALL");

  const userDetails = useSelector((state: any) => {
    return state.AuthReducer.userDetails;
  });
  React.useEffect(() => {
    if(userDetails?.xmlResponse )
    {
      let parser = new xml2js.Parser();
       parser.parseString(
        userDetails?.xmlRequest  ,
        function (err:any, result:any) {
         

          console.log("RESULTTTTXMMMLLLL",result)
        }
      );
    }
  }, [userDetails]);
  React.useEffect(() => {
    dispatch(getUserDetails(props.match.params.id));
  }, []);

  return (
    <div className="mainAdminDiv">
      <div className="borderDiv">
        <h3>API Response Details</h3>
        <div className="dashbrdPage">
          <div
            dangerouslySetInnerHTML={{ __html: userDetails?.xmlResponse }}
          ></div>
        </div>
      </div>
    </div>
  );
}
