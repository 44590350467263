import './index.css';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { _getApplicationStatusApi } from '../../redux/Actions/FinanceFormActions';
import { RootState } from '../../redux/Reducers';
import Tick from '../../img/tick.png';
import { Link } from 'react-router-dom';
import { APPLICANT_ROUTES, _getVendorId, ROUTES } from '../../services';
import { _getApplicationNum } from '../../services/helperFunctionServices';

export default function Index(props: any) {
    const dispatch = useDispatch();
    const _vendorID = _getVendorId(props);
    const applicationNum = _getApplicationNum(props) as string;
    const [status, setStatusApi] = useState<any>();
    // let { financeForm, isCoApplicant } = useSelector((state: RootState) => {
    //     return state.FinanceFormReducer;
    // });
    useEffect(() => {
        (async () => {
            if (applicationNum) {
                const aNumber = atob(applicationNum);
                console.log('Inside Use Effect', aNumber);
                const applicationStatus: any = await dispatch(_getApplicationStatusApi(aNumber));
                console.log({ applicationStatus });
                if (applicationStatus && applicationStatus?.specialFinanceApiStatus === 1) {
                    setStatusApi(true);
                } else {
                    setStatusApi(false);
                }
            } else {
                setStatusApi(false);
            }
            dispatch({ type: 'CLEAR_FORM_DATA' });
        })();
    }, []);
    return (
        <div className="mainSuccess">
            <div className="loanForm1">
                <div className="successMain">
                    <div className="inSuccess">
                        {status ? (
                            <>
                                <img src={Tick} alt="Success" className="successTick" />
                                <h4>Thank you!</h4>
                                <p>We have received your loan application and are processing it now!</p>
                                <p>If we need further information our support team will contact you. </p>
                                <p>We look forward to serving you! </p>
                                {/* <div style={{ textAlign: 'center' }}>
                                    <ul>
                                        <p>If we need further information our support team will contact you. </p>
                                        <h5>1. Copy of your Driver's License </h5>
                                        <h5>2. Copy Credit/Debit Card </h5>
                                        <h5>3. Copy of Void check </h5>
                                        <p>We look forward to serving you! </p>
                                    </ul>
                                </div> */}
                            </>
                        ) : status === false ? (
                            <>
                                <h4 style={{ color: 'red' }}> Lender API Response Failed</h4>
                                <p>Please try again with correct information</p>
                            </>
                        ) : (
                            <p>Loading....</p>
                        )}

                        <Link to={APPLICANT_ROUTES.PersonalInformation + _vendorID}>
                            Click here to fill a new form.
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
