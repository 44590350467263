export const LOADING_FAILURE = 'LOADING_FAILURE';
export const LOADING_STARTED = 'LOADING_STARTED';
export const LOADING_COMPLETED = 'LOADING_COMPLETED';

export const IS_USER_LOGIN = 'IS_USER_LOGIN';
export const LOGIN_DATA = 'LOGIN_DATA';

export const SIGN_UP = 'SIGN_UP';
export const USER_TRANSACTION="USER_TRANSACTION";
export const APPLICANT_DATA = 'APPLICANT_DATA';

export const USER_DETAILS = 'USER_DETAILS';
export const USER_REQUEST="USER_REQUEST";

export const UPDATE_VENDOR = 'UPDATE_VENDOR';
export const GET_VENDOR_DETAILS = 'GET_VENDOR_DETAILS';
