import React, { useState } from "react";
import "../index.css";
import Table from "../../../components/Table/Table";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { getUserDetails, getUserRequest } from "../../../redux/Actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import xml2js from 'xml2js';


export default function Index(props: any) {
  const rowLimit: number = 10;
  const dispatch = useDispatch();
 const [request,setRequest]=useState<any>()

  const userRequest = useSelector((state: any) => {
    return state.AuthReducer.userRequest;
  });
  console.log("user requestttttttttttt",userRequest)
  React.useEffect(() => {
    dispatch(getUserRequest(props.match.params.id));
  }, []);

  React.useEffect(() => {
    if(userRequest?.xmlRequest)
    {
      let parser = new xml2js.Parser();
       parser.parseString(
        userRequest?.xmlRequest  ,
        function (err:any, result:any) {
          setRequest(result);

          console.log("RESULTTTT",result)
        }
      );
    }
  }, [userRequest]);

  return (
    <div className="mainAdminDiv">
      <div className="borderDiv">
        <h3>API Request Details</h3>
        <div className="dashbrdPage">
       { request ?      <div> 
         { Object.keys(request?.applicationXML).map((data,i)=>{
          return request?.applicationXML[data].map((keysData:any)=>{
            return Object.keys(keysData).map((keys,i)=>{
           return   <p>{keys}: {Object.values(keysData)[i]} </p>
            })

          })
         })}
       </div>:<>No data found</>}
        </div>
      </div>
    </div>
  );
}
