import React, { useState } from 'react';
import '../index.css';
import Table from '../../../components/Table/Table';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import {
    getAdminUserDetailsAction,
    getAdminFailureAction,
    getAdminSuccessAction,
} from '../../../redux/Actions/AuthAction';

import { useDispatch, useSelector } from 'react-redux';

export default function Index(props: any) {
    const rowLimit: number = 10;
    const dispatch = useDispatch();
    const [page, setPage] = useState<number>(1);
    const [category, setCategory] = useState<string>('ALL');
    const { _vendorID } = props.match.params;
    const applicantData = useSelector((state: any) => {
        return state.AuthReducer.applicantData;
    });
    React.useEffect(() => {
        dispatch(getAdminUserDetailsAction(0));
    }, []);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(applicantData.count / rowLimit); i++) {
        pageNumbers.push(i);
    }

    const onChangePage = (pageNumber: number) => {
        if (pageNumber !== page) {
            setPage(pageNumber);
            category === 'ALL'
                ? dispatch(getAdminUserDetailsAction(pageNumber * rowLimit - rowLimit))
                : category === 'SUCCESS'
                ? dispatch(getAdminSuccessAction(pageNumber * rowLimit - rowLimit))
                : dispatch(getAdminFailureAction(pageNumber * rowLimit - rowLimit));
        }
    };
    const onClickViewDetails = (_id: string,type:string) => {
        if(type==="request"){
            props.history.push('/vendoradmin/userRequest/' + _id);
            return;
        }
     else if(type==="transaction"){
            props.history.push('/vendoradmin/userTransactionData/' + _id);
            return;
        }
        props.history.push('/vendoradmin/userDetails/' + _id);
    };
    return (
        <div className="mainAdminDiv">
            <div className="borderDiv">
                <h3>Transactions </h3>
                <div className="btnFlex">
                    <button
                        className={category === 'ALL' ? 'btn buttonActive' : 'btn'}
                        onClick={() => {
                            setCategory('ALL');
                            dispatch(getAdminUserDetailsAction(0));
                        }}
                    >
                        All
                    </button>
                    <button
                        className={category === 'SUCCESS' ? 'btn buttonActive' : 'btn'}
                        onClick={() => {
                            setCategory('SUCCESS');
                            dispatch(getAdminSuccessAction(0));
                        }}
                    >
                        <span className="green">{applicantData?.successCount}</span> Success
                    </button>
                    <button
                        className={category === 'FAILED' ? 'btn buttonActive' : 'btn'}
                        onClick={() => {
                            setCategory('FAILED');
                            dispatch(getAdminFailureAction(0));
                        }}
                    >
                        <span className="red">{applicantData?.failureCount}</span> Fail
                    </button>
                </div>
                <div className="dashbrdPage">
                    <Table data={applicantData} onClickViewDetails={onClickViewDetails} />
                </div>
                <div className="paginationDiv">
                    {/* <div>
            <ArrowBackIcon />
          </div> */}
                    {pageNumbers?.length > 0 &&
                        pageNumbers.map((pageNumber: number, i: number) => {
                            return (
                                <div
                                    className={pageNumber === page ? 'buttonActive' : ''}
                                    onClick={() => onChangePage(pageNumber)}
                                    key={i}
                                >
                                    {pageNumber}
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
}
