import { useState } from 'react';

import './Login.css';
import { validateLogIn } from './Validate';
import { forgetPasswordAction, loginAction } from '../../../redux/Actions/AuthAction';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../services';

interface LoginIProps {
    email: string | undefined;
    newPassword: string | undefined;
    confirmPassword: string | undefined;
}

interface ValidateForm {
    isFormValid: boolean;
    error: LoginIProps;
}

const Index: React.FC = (props: any) => {
    const dispatch = useDispatch();

    const [state, setState] = useState();
    const [error, setError] = useState<LoginIProps | null>(null);

    const onHandleChange = (event: any) => {
        const { name, value } = event.target;
        setState((prevState: any) => ({ ...prevState, [name]: value }));
    };
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        let response: ValidateForm = await validateLogIn(state);
        if (response.isFormValid) {
            await dispatch(forgetPasswordAction(state, 'vendor'));
        } else {
            setError(response.error);
        }
    };
    return (
        <div className="commnbg loginFixed">
            <div className="formwidth loginform">
                <form>
                    <h3>Forget Password</h3>
                    <div className="formItem">
                        <div className="form-group">
                            <label className="input__label">
                                Email
                                <input
                                    className="inptField"
                                    name="email"
                                    placeholder="Email"
                                    onChange={onHandleChange}
                                ></input>
                            </label>
                            {error?.email && <p>{error?.email}</p>}
                        </div>

                        {/* <div className="form-group">
                            <label className="input__label">
                                New Password
                                <input
                                    className="inptField"
                                    name="newPassword"
                                    type="password"
                                    placeholder="New Password"
                                    onChange={onHandleChange}
                                ></input>
                            </label>
                            {error?.newPassword && <p>{error?.newPassword}</p>}
                        </div>

                        <div className="form-group">
                            <label className="input__label">
                                Confirm Password
                                <input
                                    className="inptField"
                                    name="confirmPassword"
                                    placeholder="Confirm Password"
                                    type="password"
                                    onChange={onHandleChange}
                                ></input>
                            </label>
                            {error?.confirmPassword && <p>{error?.confirmPassword}</p>}
                        </div> */}
                    </div>
                    <div className="checkdiv">
                        <Link to={ROUTES.Login}>Login?</Link>
                    </div>
                    <button className="submitBtn" onClick={handleSubmit}>
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Index;
