import "./SideButtons.css";

function SideButtons() {
  return (
    <div className="sideButtonsNav">
        <button>1</button>
        <button>2</button>
        <button>3</button>
        <button>4</button>
    </div>
  );
}

export default SideButtons;
