import { Link } from 'react-router-dom';
import { ROUTES } from '../../services';
import './Footer.css';
import { Person } from '@material-ui/icons';
function Footer() {
    return (
        <div className="footer">
            &copy; 2021 ELEVATE
            {/* <div className="admin-link">
                <Link to={ROUTES.Login}>
                    <Person />
                    &nbsp;Admin
                </Link>
            </div> */}
        </div>
    );
}

export default Footer;
