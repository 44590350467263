import { useEffect } from 'react';
import Routing from './routing/index';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { MatchTokenAction } from './redux/Actions/AuthAction';
import { _getVendorDetails } from './redux/Actions/FinanceFormActions';
import { useLoginCheck } from './hooks/useLoginCheck';
import './App.css';
import { withRouter } from 'react-router-dom';

function App(props: any) {
    useLoginCheck(MatchTokenAction);

    return (
        <div className="App">
            <Header />
            <div className="outerDiv">
                <Routing />
            </div>
            <Footer />
        </div>
    );
}

export default withRouter(App);
