import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkValidator } from './validate';
import { applicantInformation, coApplicantInformation } from '../../redux/Actions/FinanceFormActions';
import '../index.css';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { RootState } from '../../redux/Reducers';
import { APPLICANT_ROUTES, CO_APPLICANT_ROUTES, _getVendorId } from '../../services';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DropDown from '../../components/DropDown';
import { US_STATE } from '../../utils/UsStates';

export default function Inedx(props: any) {
    const dispatch = useDispatch();
    const _vendorID = _getVendorId(props);
    const { role } = props.match.params;
    const [state, setState] = useState<any>({});
    const [error, setError] = useState<any>({});
    const { financeForm, vendorInfo } = useSelector((state: RootState) => {
        return state.FinanceFormReducer;
    });
    useEffect(() => {
        // console.log("TEST", props.match.params.role);
        if (role === 'applicant' && financeForm.applicant && financeForm.applicant.addressInformation) {
            setState(financeForm.applicant.addressInformation);
        } else if (role === 'co-applicant' && financeForm.coApplicant && financeForm.coApplicant.addressInformation) {
            setState(financeForm.coApplicant.addressInformation);
        }
    }, []);

    const handleChange = (event: any, type: string) => {
        event.preventDefault();
        let { name, value } = event.target;
        if (type === 'button') {
            setState((prevState: any) => ({ ...prevState, ownrent: value }));
        } else setState((prevState: any) => ({ ...prevState, [name]: value }));
    };
    const onSubmit = async (event: any) => {
        console.log('submitting');
        event.preventDefault();
        const validateResponse = await checkValidator(state);
        console.log('state', validateResponse);
        if (validateResponse?.isFormValid) {
            setError({});
            if (role === 'applicant') {
                dispatch(applicantInformation({ addressInformation: state }, 3));
                props.history.push(APPLICANT_ROUTES.EmploymentInformation + _vendorID);
            } else {
                dispatch(coApplicantInformation({ addressInformation: state }, 3));
                props.history.push(CO_APPLICANT_ROUTES.EmploymentInformation + _vendorID);
            }
        } else setError(validateResponse.error);
    };
    return (
        <>
            {vendorInfo && vendorInfo?.isActive === 1 ? (
                <div className="loanForm">
                    <h3>Address Information</h3>
                    <form className="d-flex commnform">
                        <div className="form-group fullline">
                            <label>
                                Address <sup>*</sup>
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                value={state?.address}
                                placeholder="Address"
                                name="address"
                                maxLength={50}
                                required
                                onChange={(event: any) => handleChange(event, 'text')}
                            />
                            <div className="error">{error.address ?? error.address}</div>
                        </div>
                        <div className="form-group nameDiv">
                            <label>
                                City <sup>*</sup>
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                value={state?.city}
                                placeholder="City"
                                name="city"
                                maxLength={50}
                                onChange={(event: any) => handleChange(event, 'text')}
                            />
                            <div className="error">{error.city ?? error.city}</div>
                        </div>
                        <div className="form-group nameDiv">
                            <DropDown
                                name="state"
                                label="State"
                                required={true}
                                handleChange={(event: any) => handleChange(event, 'dropdown')}
                                value={state?.state}
                                data={US_STATE}
                            />
                            <div className="error">{error.state ?? error.state}</div>
                        </div>
                        <div className="form-group nameDiv">
                            <label>
                                Zip <sup>*</sup>
                            </label>
                            <input
                                className="form-control"
                                type="number"
                                value={state?.zip}
                                placeholder="Zip"
                                name="zip"
                                onChange={(event: any) => handleChange(event, 'text')}
                            />
                            <div className="error">{error.zip ?? error.zip}</div>
                        </div>

                        <div className="form-group fulltag">
                            <div>
                                <label>
                                    Time at Address <sup>*</sup>
                                </label>
                            </div>
                            <div className="fullwidth">
                                <span className="tagspan">
                                    <input
                                        className="form-control"
                                        type="number"
                                        placeholder=""
                                        value={state?.taaYears}
                                        name="taaYears"
                                        required={true}
                                        onChange={(event: any) => handleChange(event, 'number')}
                                    />

                                    <div className="tag">Years</div>
                                    <div className="error">{error.taaYears ?? error.taaYears}</div>
                                </span>
                                <span className="tagspan">
                                    <input
                                        className="form-control"
                                        type="number"
                                        placeholder=""
                                        name="taaMonths"
                                        value={state?.taaMonths}
                                        // value={state?.taaMonths}
                                        onChange={(event: any) => handleChange(event, 'number')}
                                    />
                                    <div className="tag">Months</div>
                                    <div className="error">{error.taaMonths ?? error.taaMonths}</div>
                                </span>
                            </div>
                        </div>

                        <div className="form-group fullline">
                            <div>
                                <label>
                                    Own or Rent? <sup>*</sup>
                                </label>
                            </div>
                            <div className="fullwidth ownrent">
                                <button
                                    onClick={(e) => {
                                        handleChange(e, 'button');
                                    }}
                                    value="Own"
                                    className={
                                        state?.ownrent === 'Own'
                                            ? 'form-control creditbtn buttonActive'
                                            : 'form-control creditbtn'
                                    }
                                >
                                    Own
                                </button>
                                <button
                                    onClick={(e) => {
                                        handleChange(e, 'button');
                                    }}
                                    value="Rent"
                                    className={
                                        state?.ownrent === 'Rent'
                                            ? 'form-control creditbtn buttonActive'
                                            : 'form-control creditbtn'
                                    }
                                >
                                    Rent
                                </button>
                                <button
                                    onClick={(e) => {
                                        handleChange(e, 'button');
                                    }}
                                    value="Parents"
                                    className={
                                        state?.ownrent === 'Parents'
                                            ? 'form-control creditbtn buttonActive'
                                            : 'form-control creditbtn'
                                    }
                                >
                                    Parents/Relative
                                </button>
                                <button
                                    onClick={(e) => {
                                        handleChange(e, 'button');
                                    }}
                                    value="Others"
                                    className={
                                        state?.ownrent === 'Others'
                                            ? 'form-control creditbtn buttonActive'
                                            : 'form-control creditbtn'
                                    }
                                >
                                    Others
                                </button>
                            </div>
                            <div className="error">{error.ownrent ?? error.ownrent}</div>
                        </div>

                        <div className="form-group oneline">
                            <label>
                                Housing Payment<sup>*</sup>
                            </label>
                            <input
                                className="form-control"
                                type="number"
                                required
                                maxLength={10}
                                value={state?.housingPayment}
                                placeholder="Housing Payment"
                                name="housingPayment"
                                onChange={(event: any) => handleChange(event, 'text')}
                            />
                            <div className="error">{error.housingPayment ?? error.housingPayment}</div>
                        </div>

                        <h3 className="secondH3">Previous Address Information:</h3>
                        <div className="form-group fullline">
                            <label>Previous Address</label>
                            <input
                                className="form-control"
                                type="text"
                                value={state?.prevAddress}
                                placeholder="Pevious Address"
                                name="prevAddress"
                                maxLength={50}
                                onChange={(event: any) => handleChange(event, 'text')}
                            />
                            <div className="error">{error.prevAddress ?? error.prevAddress}</div>

                        </div>
                        <div className="form-group nameDiv">
                            <label>Previous City</label>
                            <input
                                className="form-control"
                                type="text"
                                value={state?.prevCity}
                                placeholder="Previous City"
                                name="prevCity"
                                maxLength={50}
                                onChange={(event: any) => handleChange(event, 'text')}
                            />
                            <div className="error">{error.prevCity ?? error.prevCity}</div>

                        </div>
                        <div className="form-group nameDiv">
                            <DropDown
                                name="prevState"
                                required={false}
                                label="Previous State"
                                handleChange={(event: any) => handleChange(event, 'dropdown')}
                                value={state?.prevState}
                                data={US_STATE}
                            />
                            {/* <label>Previous State</label>
                    <input
                        className="form-control"
                        type="text"
                        maxLength={2}
                        value={state?.prevState}
                        placeholder="Previous State"
                        name="prevState"
                        onChange={(event: any) => handleChange(event, 'text')}
                    /> */}
                        </div>

                        <div className="form-group nameDiv">
                            <label>Previous Zip</label>
                            <input
                                className="form-control"
                                type="number"
                                value={state?.prevZip}
                                placeholder="Previous Zip"
                                name="prevZip"
                                maxLength={10}
                                onChange={(event: any) => handleChange(event, 'text')}
                            />
                        </div>
                        <div className="form-group fulltag">
                            <div>
                                <label>Previous Time at Address</label>
                            </div>
                            <div className="fullwidth">
                                <span className="tagspan">
                                    <input
                                        className="form-control"
                                        type="number"
                                        placeholder=""
                                        value={state?.prevtaaYears}
                                        name="prevtaaYears"
                                        min={0}
                                        max={99}
                                        onChange={(event: any) => handleChange(event, 'number')}
                                    />

                                    <div className="tag">Years</div>
                                    <div className="error">{error.prevtaaYears ?? error.prevtaaYears}</div>
                                </span>
                                <span className="tagspan">
                                    <input
                                        className="form-control"
                                        type="number"
                                        placeholder=""
                                        min={0}
                                        max={11}
                                        name="prevtaaMonths"
                                        value={state?.prevtaaMonths}
                                        // value={state?.prevtaaMonths}
                                        onChange={(event: any) => handleChange(event, 'number')}
                                    />

                                    <div className="tag">Months</div>
                                    <div className="error">{error.prevtaaMonths ?? error.prevtaaMonths}</div>
                                </span>
                            </div>
                        </div>

                        <div className="absArrws">
                            <div>
                                <button
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        props.history.goBack();
                                    }}
                                    className="arrBtn arrowBackward"
                                >
                                    <ArrowBackIosIcon />
                                </button>
                            </div>
                            <div>
                                <button onClick={onSubmit} className="arrBtn">
                                    <ArrowForwardIosIcon />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            ) : (
                <h3 className="loanForm inactiveVendor">Inactive Vendor</h3>
            )}
        </>
    );
}
