
function containSpecialCharacter(str:any) {
    var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
//            ^                                       ^   
return format.test(str);
  }

export const checkValidator = (formData: any) => {


    let isFormValid: boolean = true;
    let error: any = {
        firstName: '',
        midName:"",
        lastName: '',
        dob: '',
        ssn: '',
        homPhone: '',
        moneyRequested: '',
    };
    console.log('formData', formData);
    if (formData === undefined) {
        isFormValid = false;
        error = {
            firstName: 'This field is required.',
            lastName: 'This field is required.',
            dob: 'This field is required.',
            ssn: 'This field is required.',
            homPhone: 'This field is required.',
            moneyRequested: 'This field is required.',
        };
    }
    if (
        formData &&
        (formData?.moneyRequested == undefined ||
            formData?.moneyRequested == '' ||
            formData?.moneyRequested.trim().length == 0)
    ) {
        isFormValid = false;
        error.moneyRequested = 'This Field cannot be empty!';
    }

    if (
        formData &&
        (formData?.firstName == undefined || formData?.firstName == '' || formData?.firstName.trim().length == 0)
    ) {
        isFormValid = false;
        error.firstName = 'This Field cannot be empty!';
    }
    else if(containSpecialCharacter(formData?.firstName)){
        isFormValid = false;
        error.firstName = 'Please remove special characters';

    }
    if(containSpecialCharacter(formData?.midName)){
        isFormValid = false;
        error.midName = 'Please remove special characters';

    }
    if (
        formData &&
        (formData?.lastName == undefined || formData?.lastName == '' || formData?.lastName.trim().length == 0)
    ) {
        isFormValid = false;
        error.lastName = 'This Field cannot be empty!';
    }
    else if(containSpecialCharacter(formData?.lastName)){
        isFormValid = false;
        error.lastName = 'Please remove special characters';

    }
    if (formData && (formData?.dob == undefined || formData?.dob == '' || formData?.dob.trim().length == 0)) {
        isFormValid = false;
        error.dob = 'This Field cannot be empty!';
    }
    if (formData && (formData?.ssn == undefined || formData?.ssn == '' || formData?.ssn.trim().length == 0)) {
        isFormValid = false;
        error.ssn = 'This Field cannot be empty!';
    } else if (formData && formData?.ssn && formData?.ssn.trim().length !== 11) {
        isFormValid = false;
        error.ssn = 'Please provide  digit ssn number';
    }

    if (
        formData &&
        formData.email &&
        formData.email.trim().length > 0 &&
        /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(formData.email) === false
    ) {
        isFormValid = false;
        error.email = 'Email must be a valid email address.';
    }

    if (
        formData &&
        (formData?.homPhone == undefined || formData?.homPhone == '' || formData?.homPhone.trim().length == 0)
    ) {
        isFormValid = false;
        error.homPhone = 'This Field cannot be empty!';
    } else if (formData && formData?.homPhone && formData?.homPhone.trim().length !== 12) {
        isFormValid = false;
        error.homPhone = 'Please provide 10 digit number';
    }
    if (formData && formData?.busPhone && formData?.busPhone.trim().length !== 12) {
        isFormValid = false;
        error.busPhone = 'Please provide 10 digit number';
    }
    if (formData && formData?.celPhone && formData?.celPhone.trim().length !== 12) {
        isFormValid = false;
        error.celPhone = 'Please provide 10 digit number';
    }
    return {
        isFormValid,
        error,
    };
};
