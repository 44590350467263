import { ProgressBar } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

function Index(props: any) {
    const applicantSteps = useSelector((state: any) => {
        return state.FinanceFormReducer.applicantSteps;
    });
    const coApplicantSteps = useSelector((state: any) => {
        return state.FinanceFormReducer.coApplicantSteps;
    });
    const isCoApplicant = useSelector((state: any) => {
        return state.FinanceFormReducer.isCoApplicant;
    });
    return (
        <>
            {props.location.pathname.includes('admin') ||
            props.location.pathname === '/success' ||
            props.location.pathname === '/welcome' ? null : (
                <div className="titleBar">
                    <div className="progressBarParent">
                        <h4>Applicant</h4>
                        <ProgressBar className="progressBar" striped variant="info" now={applicantSteps.length * 20} />
                    </div>
                    {isCoApplicant ? (
                        <div className="progressBarParent">
                            <h4>Co-Applicant</h4>
                            <ProgressBar
                                className="progressBar"
                                striped
                                variant="info"
                                now={coApplicantSteps.length * 20}
                            />
                        </div>
                    ) : null}
                </div>
            )}
        </>
    );
}

export default withRouter(Index);
