import cogoToast from "cogo-toast";

class Toaster {
  success = (message: string) => {
    let options:object = { position: "top-right", heading: "Success" };
    cogoToast.success(message, options);
  };

  error = (message: string) => {
    let options:object = { position: "top-right", heading: "Error" };
    cogoToast.error(message, options);
  };
}

export const toast = new Toaster();