
function containSpecialCharacter(str:any) {
    var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;                      
return format.test(str);
  }

export const checkValidator = (formData: any) => {
    let isFormValid: boolean = true;
    let error: any = {
        employer: '',
        employedBy: '',
        occupation: '',
        empAddress: '',
        empCity: '',
        empState: '',
        empZip: '',
        loeYears: '',
        loeMonths: '',
        prevEmployer:"",
        prevempAddress:"",
        prevempCity:"",
        prevOccupation:""
    };
    console.log('formData', formData);

    if (
        formData &&
        (formData?.employer == undefined || formData?.employer == '' || formData?.employer.trim().length == 0)
    ) {
        isFormValid = false;
        error.employer = 'This Field cannot be empty!';
    }
else if(containSpecialCharacter(formData?.employer)){
    isFormValid = false;
        error.employer = 'Please remove special characters';

}
    if (formData && formData?.employedBy == undefined) {
        isFormValid = false;
        error.employedBy = 'Please choose an option';
    }

    if (
        formData &&
        (formData?.occupation == undefined || formData?.occupation == '' || formData?.occupation.trim().length == 0)
    ) {
        isFormValid = false;
        error.occupation = 'This Field cannot be empty!';
    }
    else if(containSpecialCharacter(formData?.occupation)){
        isFormValid = false;
            error.occupation = 'Please remove special characters';
}
if(containSpecialCharacter(formData?.prevOccupation)){
    isFormValid = false;
        error.prevOccupation = 'Please remove special characters';
}
    if (
        formData &&
        (formData?.empAddress == undefined || formData?.empAddress == '' || formData?.empAddress.trim().length == 0)
    ) {
        isFormValid = false;
        error.empAddress = 'This Field cannot be empty!';
    }
    else if(containSpecialCharacter(formData?.empAddress)){
        isFormValid = false;
            error.empAddress = 'Please remove special characters';
    
    }
    if (
        formData &&
        (formData?.empCity == undefined || formData?.empCity == '' || formData?.empCity.trim().length == 0)
    ) {
        isFormValid = false;
        error.empCity = 'This Field cannot be empty!';
    }
    else if(containSpecialCharacter(formData?.empCity)){
        isFormValid = false;
            error.empCity = 'Please remove special characters';
    
    }
    if(containSpecialCharacter(formData?.prevempCity)){
        isFormValid = false;
            error.prevempCity = 'Please remove special characters';
    
    }
    if(containSpecialCharacter(formData?.prevempAddress)){
        isFormValid = false;
            error.prevempAddress = 'Please remove special characters';
    
    }
    if(containSpecialCharacter(formData?.prevEmployer)){
        isFormValid = false;
            error.prevEmployer = 'Please remove special characters';
    
    }

    if (
        formData &&
        (formData?.empState == undefined || formData?.empState == '' || formData?.empState.trim().length == 0)
    ) {
        isFormValid = false;
        error.empState = 'This Field cannot be empty!';
    }
    if (formData && (formData?.empZip == undefined || formData?.empZip == '' || formData?.empZip.trim().length == 0)) {
        isFormValid = false;
        error.empZip = 'This Field cannot be empty!';
    }
    if (formData && formData?.empZip && (formData?.empZip.length > 7 || formData?.empZip.length < 0)) {
        isFormValid = false;
        error.empZip = 'Zip code must be less than 7 digits.';
    }

    if (formData && (Number(formData?.loeYears) >= 100 || Number(formData?.loeYears) < 0)) {
        isFormValid = false;
        error.loeYears = 'Year should be less than 100.';
    } else if (formData && (formData?.loeYears == '' || formData?.loeYears == undefined)) {
        isFormValid = false;
        error.loeYears = 'This Field cannot be empty!';
    }
    if (formData && formData?.loeMonths && (Number(formData?.loeMonths) > 11 || Number(formData?.loeMonths) < 0)) {
        isFormValid = false;
        error.loeMonths = 'Months should be less than 12.';
    } else if (formData && (formData?.loeMonths == '' || formData?.loeMonths == undefined)) {
        isFormValid = false;
        error.loeMonths = 'This Field cannot be empty!';
    }

    return {
        isFormValid,
        error,
    };
};
