import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkValidator } from './validate';
import { applicantInformation, coApplicantInformation } from '../../redux/Actions/FinanceFormActions';
import '../index.css';
import { ROUTES, RoleContext } from '../../services';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { RootState } from '../../redux/Reducers';
import { APPLICANT_ROUTES, CO_APPLICANT_ROUTES, _getVendorId } from '../../services';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DropDown from '../../components/DropDown';
import { US_STATE } from '../../utils/UsStates';

export default function Index(props: any) {
    const dispatch = useDispatch();
    const _vendorID = _getVendorId(props);
    const [state, setState] = useState<any>({});
    const [error, setError] = useState<any>({});
    const { financeForm, vendorInfo } = useSelector((state: RootState) => {
        return state.FinanceFormReducer;
    });
    const { role } = props.match.params;
    useEffect(() => {
        if (role === 'applicant' && financeForm.applicant && financeForm.applicant.employmentInformation) {
            setState(financeForm.applicant.employmentInformation);
        } else if (
            role === 'co-applicant' &&
            financeForm.coApplicant &&
            financeForm.coApplicant.employmentInformation
        ) {
            setState(financeForm.coApplicant.employmentInformation);
        }
    }, []);
    const handleChange = (event: any, type: string) => {
        event.preventDefault();
        let { name, value } = event.target;
        // console.log("name, value", name, "d", value);
        if (type === 'checkbox') {
            setState((prevState: any) => ({
                ...prevState,
                [name]: event.target.checked,
            }));
        } else if (type === 'button') {
            setState((prevState: any) => ({ ...prevState, employedBy: value }));
        } else setState((prevState: any) => ({ ...prevState, [name]: value }));
    };
    const onSubmit = async (event: any) => {
        event.preventDefault();
        const validateResponse = await checkValidator(state);
        // console.log("state", state);
        if (validateResponse?.isFormValid) {
            if (role === 'applicant') {
                dispatch(applicantInformation({ employmentInformation: state }, 4));
                props.history.push(APPLICANT_ROUTES.IncomeInformation + _vendorID);
            } else {
                dispatch(coApplicantInformation({ employmentInformation: state }, 4));
                props.history.push(CO_APPLICANT_ROUTES.IncomeInformation + _vendorID);
            }
        } else setError(validateResponse.error);
    };
    return (
        <>
            {vendorInfo && vendorInfo?.isActive === 1 ? (
                <div className="loanForm ">
                    <h3>Employment Information</h3>
                    <form className="d-flex commnform">
                        <div className="form-group fullline">
                            <label>
                                Employer Name<sup>*</sup>
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                maxLength={50}
                                placeholder="Employer Name"
                                name="employer"
                                value={state?.employer}
                                onChange={(event: any) => handleChange(event, 'text')}
                            />
                            <div className="error">{error.employer ?? error.employer}</div>
                        </div>
                        <div className="form-group fullline">
                            <div>
                                <label>
                                    Employed by<sup>*</sup>
                                </label>
                            </div>
                            <div className="fullwidth ownrent">
                                <button
                                    onClick={(e) => {
                                        handleChange(e, 'button');
                                    }}
                                    value="Self"
                                    className={
                                        state?.employedBy === 'Self'
                                            ? 'form-control creditbtn buttonActive'
                                            : 'form-control creditbtn'
                                    }
                                >
                                    Self
                                </button>
                                <button
                                    onClick={(e) => {
                                        handleChange(e, 'button');
                                    }}
                                    value="Others"
                                    className={
                                        state?.employedBy === 'Others'
                                            ? 'form-control creditbtn buttonActive'
                                            : 'form-control creditbtn'
                                    }
                                >
                                    Others
                                </button>
                            </div>
                            <div className="error">{error.employedBy ?? error.employedBy}</div>
                        </div>
                        <div className="form-group fullline">
                            <label>
                                Job Description<sup>*</sup>
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Job Description"
                                name="occupation"
                                maxLength={50}
                                value={state?.occupation}
                                onChange={(event: any) => handleChange(event, 'text')}
                            />
                            <div className="error">{error.occupation ?? error.occupation}</div>
                        </div>

                        <div className="form-group fullline">
                            <label>
                                Employer Address<sup>*</sup>
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                maxLength={50}
                                placeholder="Employer Address"
                                name="empAddress"
                                value={state?.empAddress}
                                onChange={(event: any) => handleChange(event, 'text')}
                            />
                            <div className="error">{error.empAddress ?? error.empAddress}</div>
                        </div>
                        <div className="form-group nameDiv">
                            <label>
                                Employer City<sup>*</sup>
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                maxLength={50}
                                placeholder="Employer City"
                                name="empCity"
                                value={state?.empCity}
                                onChange={(event: any) => handleChange(event, 'text')}
                            />
                            <div className="error">{error.empCity ?? error.empCity}</div>
                        </div>
                        <div className="form-group nameDiv">
                            <DropDown
                                name="empState"
                                required={true}
                                label="Employer State"
                                handleChange={(event: any) => handleChange(event, 'dropdown')}
                                value={state?.empState}
                                data={US_STATE}
                            />
                            <div className="error">{error.empState ?? error.empState}</div>

                            {/* <label>Employer State</label>
          <input
            className="form-control"
            type="text"
            maxLength={2}
            placeholder="Employer State"
            name="empState"
            value={state?.empState}
            onChange={(event: any) => handleChange(event, "text")}
          /> */}
                        </div>

                        <div className="form-group nameDiv">
                            <label>
                                Employer Zip<sup>*</sup>
                            </label>
                            <input
                                className="form-control"
                                type="number"
                                maxLength={10}
                                placeholder="Employer Zip"
                                name="empZip"
                                value={state?.empZip}
                                onChange={(event: any) => handleChange(event, 'text')}
                            />
                            <div className="error">{error.empZip ?? error.empZip}</div>
                        </div>

                        <div className="form-group fulltag">
                            <div>
                                <label>
                                    Years at Current Employers<sup>*</sup>
                                </label>
                            </div>
                            <div className="fullwidth">
                                <span className="tagspan">
                                    <input
                                        className="form-control"
                                        type="number"
                                        placeholder=""
                                        // value={state?.loeYears}
                                        value={state?.loeYears}
                                        name="loeYears"
                                        min={0}
                                        max={99}
                                        onChange={(event: any) => handleChange(event, 'number')}
                                    />

                                    <div className="tag">Years</div>
                                    <div className="error">{error.loeYears ?? error.loeYears}</div>
                                </span>
                                <span className="tagspan">
                                    <input
                                        className="form-control"
                                        type="number"
                                        placeholder=""
                                        min={0}
                                        max={11}
                                        name="loeMonths"
                                        value={state?.loeMonths}
                                        // value={state?.loeMonths}
                                        onChange={(event: any) => handleChange(event, 'number')}
                                    />

                                    <div className="tag">Months</div>
                                    <div className="error">{error.loeMonths ?? error.loeMonths}</div>
                                </span>
                            </div>
                        </div>
                        <h3 className="secondH3">Previous Employer Information:</h3>
                        <div className="form-group fullline">
                            <label>Previous Employer Name</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Previous Employer Name"
                                name="prevEmployer"
                                maxLength={50}
                                value={state?.prevEmployer}
                                onChange={(event: any) => handleChange(event, 'text')}
                            />
                           <div className="error">   {error.prevEmployer ?? error.prevEmployer}
                           </div>     </div>
                        <div className="form-group fullline">
                            <label>Previous Job Description</label>
                            <input
                                className="form-control"
                                type="text"
                                maxLength={50}
                                placeholder="Previous Job Description"
                                name="prevOccupation"
                                value={state?.prevOccupation}
                                onChange={(event: any) => handleChange(event, 'text')}
                            />
                         <div className="error"> {error.prevOccupation ?? error.prevOccupation}</div>
                        </div>

                        <div className="form-group fullline">
                            <label>Previous Employer Address</label>
                            <input
                                className="form-control"
                                type="text"
                                maxLength={50}
                                placeholder="Previous Employer Address"
                                name="prevempAddress"
                                value={state?.prevempAddress}
                                onChange={(event: any) => handleChange(event, 'text')}
                            />
                          <div className="error">   {error.prevempAddress ?? error.prevempAddress}</div>
                        </div>
                        <div className="form-group nameDiv">
                            <label>Previous Employer City</label>
                            <input
                                className="form-control"
                                type="text"
                                maxLength={50}
                                placeholder="Previous Employer City"
                                name="prevempCity"
                                value={state?.prevempCity}
                                onChange={(event: any) => handleChange(event, 'text')}
                            />
                         <div className="error">     {error.prevempCity ?? error.prevempCity} </div>

                        </div>

                        <div className="form-group nameDiv">
                            <DropDown
                                name="prevempState"
                                required={false}
                                label="Previous Employer State"
                                handleChange={(event: any) => handleChange(event, 'dropdown')}
                                value={state?.prevempState}
                                data={US_STATE}
                            />
                            {/* <label>Previous Employer State</label>
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Previous Employer State"
                        name="prevempState"
                        value={state?.prevempState}
                        maxLength={2}
                        onChange={(event: any) => handleChange(event, 'text')}
                    /> */}
                        </div>

                        <div className="form-group nameDiv">
                            <label>Previous Employer Zip</label>
                            <input
                                className="form-control"
                                type="number"
                                placeholder="Previous Employer Zip"
                                name="prevempZip"
                                maxLength={10}
                                value={state?.prevempZip}
                                onChange={(event: any) => handleChange(event, 'text')}
                            />
                        </div>

                        <div className="form-group fulltag">
                            <div>
                                <label>Previous Time at Current Employers</label>
                            </div>
                            <div className="fullwidth">
                                <span className="tagspan">
                                    <input
                                        className="form-control"
                                        type="number"
                                        placeholder=""
                                        // value={state?.prevloeYears}
                                        value={state?.prevloeYears}
                                        name="prevloeYears"
                                        min={0}
                                        max={99}
                                        onChange={(event: any) => handleChange(event, 'number')}
                                    />

                                    <div className="tag">Years</div>
                                </span>
                                <span className="tagspan">
                                    <input
                                        className="form-control"
                                        type="number"
                                        placeholder=""
                                        min={0}
                                        max={11}
                                        name="prevloeMonths"
                                        // value={state?.prevloeMonths}

                                        value={state?.prevloeMonths}
                                        onChange={(event: any) => handleChange(event, 'number')}
                                    />

                                    <div className="tag">Months</div>
                                </span>
                            </div>
                        </div>

                        <div className="absArrws">
                            <div>
                                <button
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        props.history.goBack();
                                    }}
                                    className="arrBtn arrowBackward"
                                >
                                    <ArrowBackIosIcon />
                                </button>
                            </div>
                            <div>
                                <button onClick={onSubmit} className="arrBtn">
                                    <ArrowForwardIosIcon />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            ) : (
                <h3 className="loanForm inactiveVendor">Inactive Vendor</h3>
            )}
        </>
    );
}
