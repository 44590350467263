import { useEffect } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    AddressInformation,
    EmploymentInformation,
    IncomeInformation,
    LoanInformation,
    PersonalInformation,
    SuccessPage,
    Welcome,
} from '../controllers/index';
import SideNav from '../components/sideNav/index';
import { APPLICANT_ROUTES, ROUTES, _getVendorId } from '../services';
import ScrollToTop from '../components/scrollToTop/scrollToTop';
import ButtonNav from '../components/SideButtons/SideButtons';
import ProgressBar from '../components/ProgressBar/index';
import { Dashboard, Login, ForgetPassword, ApplicantDetails, ResetPassword,ApplicantRequest,ApplicantTransactionData } from '../controllers/index';
import AdminSideNav from '../components/AdminSideNav';
import EditVendor from '../controllers/EditVendor';

function Index(props: any) {
    const _vendorID = _getVendorId(props);
    const isUserLogin = useSelector((state: any) => {
        return state.AuthReducer.isUserLogin;
    });
    useEffect(() => {
        if (!props?.location?.pathname.includes('vendoradmin')) {
            console.log('in route mount');
            props.history.push(APPLICANT_ROUTES.PersonalInformation + _vendorID);
        }
    }, []);
    return (
        <ScrollToTop>
            {props.location.pathname.includes('success') ||
            props.location.pathname.includes('welcome') ||
            props.location.pathname.includes('admin') ? null : (
                <SideNav />
            )}
            <div className="mainDiv" style={props.location.pathname == '/vendoradmin' ? { paddingLeft: 0 } : {}}>
                <ProgressBar />
                <Switch>
                    {!isUserLogin ? (
                        <>
                            <Route path={ROUTES.PersonalInformation} component={PersonalInformation} exact />
                            <Route path={ROUTES.IncomeInformation} component={IncomeInformation} exact />
                            <Route path={ROUTES.EmploymentInformation} component={EmploymentInformation} exact />
                            <Route path={ROUTES.AddressInformation} component={AddressInformation} exact />

                            <Route path={ROUTES.SuccessPage} component={SuccessPage} exact />
                            <Route path={ROUTES.WelcomePage} component={Welcome} exact />
                            <Route path={ROUTES.Login} component={Login} exact />
                            <Route path={ROUTES.ForgetPassword} component={ForgetPassword} exact />
                            <Route path={ROUTES.ResetPassword} component={ResetPassword} exact />
                            {/* <Redirect
                                to={APPLICANT_ROUTES.PersonalInformation + _vendorID}
                                from={props.history.pathname}
                            /> */}
                        </>
                    ) : (
                        <>
                            <AdminSideNav />
                            <Route path={ROUTES.Dashboard} component={Dashboard} exact />
                            <Route path={ROUTES.ApplicantDetails} component={ApplicantDetails} exact />
                            <Route path={ROUTES.ApplicantRequest} component={ApplicantRequest} exact />
                            <Route path={ROUTES.ApplicantTransactionData} component={ApplicantTransactionData} exact />

                            <Route path={`${ROUTES.EditVendor}`} component={EditVendor} exact />
                            <Redirect to={ROUTES.Dashboard} from={props.history.pathname} />
                        </>
                    )}
                </Switch>
            </div>
            {props.location.pathname.includes('admin') ||
            props.location.pathname.includes('success') ||
            props.location.pathname.includes('welcome') ? null : (
                <ButtonNav />
            )}
        </ScrollToTop>
    );
}
export default withRouter(Index);
