import { APPLICANT_ROUTES, CO_APPLICANT_ROUTES, ROUTES, _getVendorId } from '../../services';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/Reducers';

import './index.css';
import { useRef } from 'react';
import { validateUrl } from '../../services/helperFunctionServices';
import { uploadFormData } from '../../redux/Actions/FinanceFormActions';
//import Tick from "../../img/tick.png";

export default function Index(props: any) {
    const _vendorID = _getVendorId(props);
    const { vendorInfo, financeForm } = useSelector((state: RootState) => {
        return state.FinanceFormReducer;
    });
    const { role } = props.match.params;
    const dispatch = useDispatch();

    return (
        <div className="welcmMain">
            <h1>Welcome</h1>
            {/* <a href={vendorInfo?.websiteUniqueUrl} id="redirectUrl" hidden /> */}

            <div className="inWelcome">
                <h2>Do you want to add a Co-Applicant?</h2>
                <h5>Adding a co-applicant could improve your offer!</h5>
                <div className="yesno">
                    <div>
                        <label className="radioMain">
                            <input
                                type="radio"
                                className="radiobtn"
                                name="yesno"
                                onClick={() => {
                                    dispatch({ type: 'CO_APPLICANT', payload: true });
                                    props.history.push(CO_APPLICANT_ROUTES.PersonalInformation + _vendorID);
                                }}
                            />{' '}
                            Yes
                        </label>
                    </div>
                    <div>
                        <label className="radioMain">
                            <input
                                type="radio"
                                className="radiobtn"
                                name="yesno"
                                onClick={async (e) => {
                                    console.log('vendorInfo.websiteUniqueUrl', vendorInfo);

                                    await dispatch({ type: 'CO_APPLICANT', payload: false });
                                    financeForm['isCoApplicant'] = false;
                                    financeForm['_vendorID'] = _vendorID;
                                    await dispatch(uploadFormData(financeForm, vendorInfo, props));
                                    // if (
                                    //     vendorInfo.websiteUniqueUrl &&
                                    //     vendorInfo.websiteUniqueUrl != null &&
                                    //     vendorInfo.enableWebsiteUrl === 1
                                    // ) {
                                    //     // window.open(validateUrl(vendorInfo.websiteUniqueUrl), '_self');
                                    // } else {
                                    //     // props.history.push(APPLICANT_ROUTES.SuccessPage + _vendorID);
                                    // }
                                }}
                            />{' '}
                            No
                        </label>
                    </div>
                </div>
                <div className="youcan">
                    <h4>With a Co-Applicant you can:</h4>
                    <ul>
                        <li>Get more loan offers</li>
                        <li>Get lower interest rate</li>
                        <li>Save more over the life of the loan</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
