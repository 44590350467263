const initialState: any = {
    financeForm: {
        loanInformation: {},
        applicant: {},
        coApplicant: {},
    },
    coApplicantSteps: [1],
    applicantSteps: [1],
    isCoApplicant: false,
    vendorInfo: {},
};
export const FinanceFormReducer = (state = initialState, action: any) => {
    //   console.log("STEP",  stepsArray.push((action.step).toString()),typeof (action.step).toString() );
    switch (action.type) {
        case 'UPDATE_LOAN_FORM_DATA':
            return {
                ...state,
                applicantSteps: state['applicantSteps'].includes(action.step + 1)
                    ? state['applicantSteps']
                    : [...state['applicantSteps'], action.step + 1],
                financeForm: {
                    ...state['financeForm'],
                    loanInformation: action.payload,
                },
            };
        case 'VENDOR_INFO':
            return {
                ...state,
                vendorInfo: action.payload,
            };
        case 'APPLICANT_LOAN_FORM_DATA':
            let { applicant } = state['financeForm'];
            applicant = { ...applicant, ...action.payload };
            console.log('action payload and step', applicant);
            return {
                ...state,
                applicantSteps: state['applicantSteps'].includes(action.step)
                    ? state['applicantSteps']
                    : [...state['applicantSteps'], action.step],
                financeForm: { ...state['financeForm'], applicant: applicant },
            };
        case 'CO_APPLICANT_LOAN_FORM_DATA':
            let { coApplicant } = state['financeForm'];
            coApplicant = { ...coApplicant, ...action.payload };
            return {
                ...state,
                coApplicantSteps: [...state['coApplicantSteps'], action.step + 1],
                financeForm: { ...state['financeForm'], coApplicant: coApplicant },
            };
        case 'CO_APPLICANT':
            return {
                ...state,
                isCoApplicant: action.payload,
            };
        case 'CLEAR_FORM_DATA':
            return {
                ...state,
                financeForm: {
                    loanInformation: {},
                    applicant: {},
                    coApplicant: {},
                },
                coApplicantSteps: [1],
                applicantSteps: [1],
                isCoApplicant: false,
            };
        default:
            return state;
    }
};
