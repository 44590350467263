import { ROUTES } from '../../services';
import axios from '../../services/axiosService';
import { toast } from '../../utils/toast';
import {
    LOGIN_DATA,
    LOADING_COMPLETED,
    LOADING_FAILURE,
    LOADING_STARTED,
    IS_USER_LOGIN,
    APPLICANT_DATA,
    USER_DETAILS,
    GET_VENDOR_DETAILS,
    USER_REQUEST,
    USER_TRANSACTION,
} from '../types';
import { _getVendorDetails } from './FinanceFormActions';
export const loginAction = (body: undefined, props: any) => async (dispatch: any) => {
    try {
        dispatch({ type: LOADING_STARTED });
        const res = await axios.post('/admin/login', body);
        let { data } = res.data;
        console.log("DATAAA",res.data)
        if (res?.data?.status && res?.data?.code === 200 && data?.user?.role === 2) {
            console.log('vender data', data);
            localStorage.setItem('userData', JSON.stringify(data));
            localStorage.setItem('token', data.token);
            await dispatch({ type: LOGIN_DATA, payload: { data: data?.user } });
            await dispatch({ type: IS_USER_LOGIN, payload: true });
            await dispatch({ type: LOADING_COMPLETED });

            if (data?.user?._vendorID) {
                await dispatch(_getVendorDetails(data.user._vendorID));
                await toast.success('Login Successful');
                props.history.push(ROUTES.Dashboard);
            } else {
                await toast.success('No vender found. Please contact with Admin!');
            }
        } else {
            toast.error('Please check your credentials!');
        }
    } catch (error) {
        dispatch({ type: LOADING_FAILURE });
        toast.error('Login Error!!');
    }
};

export const forgetPasswordAction = (state: undefined, type: any) => async (dispatch: any) => {
    try {
        dispatch({ type: LOADING_STARTED });
        const res = await axios.post('/admin/forgotPassword', { state, type });
        if (res?.data?.status && res?.data?.code === 200) {
            // let { data } = res.data;
            // await toast.success("Login Successful");
            await toast.success(res?.data?.data);
        } else {
            // toast.error("Something Went Worng!");
            await toast.error(res?.data?.data);
        }
    } catch (error) {
        dispatch({ type: LOADING_FAILURE });
        await toast.error('Something went wrong!');
        // toast.error("Login Error!!");
    }
};
export const resetPasswordAction = (body: undefined, props: any) => async (dispatch: any) => {
    try {
        dispatch({ type: LOADING_STARTED });
        const res = await axios.post('/admin/resetPassword', body);
        if (res?.data?.status && res?.data?.code === 200) {
            await toast.success(res?.data?.data);
        } else {
            await toast.success(res?.data?.data);
        }
    } catch (error) {
        dispatch({ type: LOADING_FAILURE });
        await toast.success('Something went wrong!');
    }
};

export const getAdminUserDetailsAction = (skip: any) => async (dispatch: any) => {
    try {
        dispatch({ type: LOADING_STARTED });
        const res = await axios.get('/admin/getAllApplications/' + skip);
        if (res?.data?.status && res?.data?.code === 200) {
            let { data } = res.data;
            await dispatch({ type: APPLICANT_DATA, payload: data });
            await dispatch({ type: LOADING_COMPLETED });
        } else {
            // toast.error("Something Went Worng!");
        }
    } catch (error) {
        dispatch({ type: LOADING_FAILURE });
        // toast.error("Login Error!!");
    }
};

export const getAdminSuccessAction = (skip: any) => async (dispatch: any) => {
    try {
        dispatch({ type: LOADING_STARTED });
        const res = await axios.get('/admin/getAllSuccessApplications/' + skip);
        if (res?.data?.status && res?.data?.code === 200) {
            let { data } = res.data;
            await dispatch({ type: APPLICANT_DATA, payload: data });
            await dispatch({ type: LOADING_COMPLETED });
        } else {
            // toast.error("Something Went Worng!");
        }
    } catch (error) {
        dispatch({ type: LOADING_FAILURE });
        // toast.error("Login Error!!");
    }
};
export const getAdminFailureAction = (skip: any) => async (dispatch: any) => {
    try {
        dispatch({ type: LOADING_STARTED });
        const res = await axios.get('/admin/getAllFailedApplications/' + skip);
        if (res?.data?.status && res?.data?.code === 200) {
            let { data } = res.data;
            await dispatch({ type: APPLICANT_DATA, payload: data });
            await dispatch({ type: LOADING_COMPLETED });
        } else {
            // toast.error("Something Went Worng!");
        }
    } catch (error) {
        dispatch({ type: LOADING_FAILURE });
        // toast.error("Login Error!!");
    }
};

export const getUserDetails = (_id: any) => async (dispatch: any) => {
    try {
        dispatch({ type: LOADING_STARTED });
        const res = await axios.get('/admin/getApplicantDetails/' + _id);
        if (res?.data?.status && res?.data?.code === 200) {
            let { data } = res.data;
            await dispatch({ type: USER_DETAILS, payload: data });
            await dispatch({ type: LOADING_COMPLETED });
        } else {
            // toast.error("Something Went Worng!");
        }
    } catch (error) {
        dispatch({ type: LOADING_FAILURE });
        // toast.error("Login Error!!");
    }
};
export const getUserRequest = (_id: any) => async (dispatch: any) => {
    try {
        dispatch({ type: LOADING_STARTED });
        const res = await axios.get('/admin/getApplicantRequest/' + _id);
        console.log("REQQQQQQQUESTTTTTTTT",res)
        if (res?.data?.status && res?.data?.code === 200) {
            let { data } = res.data;
            await dispatch({ type: USER_REQUEST, payload: data });
            await dispatch({ type: LOADING_COMPLETED });
        } else {
            // toast.error("Something Went Worng!");
        }
    } catch (error) {
        dispatch({ type: LOADING_FAILURE });
        // toast.error("Login Error!!");
    }
};
export const getTransactionData = (_id: any) => async (dispatch: any) => {
    try {
        dispatch({ type: LOADING_STARTED });
        const res = await axios.get('/admin/getTransactionData/' + _id);
        console.log("REQQQQQQQUESTTTTTTTT",res)
        if (res?.data?.status && res?.data?.code === 200) {
            let { data } = res.data;
            await dispatch({ type: USER_TRANSACTION, payload: data });
            await dispatch({ type: LOADING_COMPLETED });
        } else {
            // toast.error("Something Went Worng!");
        }
    } catch (error) {
        dispatch({ type: LOADING_FAILURE });
        // toast.error("Login Error!!");
    }
};
export const MatchTokenAction = async (dispatch: any) => {
    try {
        const res = await axios.get('/admin/matchtoken');
        if (res?.data?.status && res?.data?.code === 200) {
            let { data } = res.data;
            console.log('data', data);
            if (data && data._vendorID) {
                await dispatch(_getVendorDetails(data._vendorID));
                await dispatch({ type: LOGIN_DATA, payload: res.data });
                localStorage.setItem('userData', JSON.stringify(data));
                localStorage.setItem('token', data.token);
            }
        } else {
            //   toast.error("Something Went Worng!");
        }
    } catch (error) {
        dispatch({ type: LOADING_FAILURE });
        // toast.error("Login Error!!");
    }
};

export const LogOutAction = (props: any) => async (dispatch: any) => {
    localStorage.clear();
    await dispatch({ type: IS_USER_LOGIN, payload: false });
    props.history.push(ROUTES.Login);
};

export const getVendorDetails =
    ({ userId }: any) =>
    async (dispatch: any) => {
        try {
            dispatch({ type: LOADING_STARTED });

            const res = await axios.get(`/admin/details/${userId}`);
            if (res?.data?.status && res?.data?.code === 200) {
                let { data } = res.data;
                console.log('data in vendorDetails', data);
                dispatch({ type: GET_VENDOR_DETAILS, payload: data });
                return data;
            } else {
                toast.error('Something Went Worng!');
            }
        } catch (error) {
            dispatch({ type: LOADING_FAILURE });
        }
    };

export const updateVendorDetails =
    ({ userId, payload }: any) =>
    async (dispatch: any) => {
        try {
            dispatch({ type: LOADING_STARTED });

            const res = await axios.post(`/admin/details/edit/${userId}`, payload);
            if (res?.data?.status && res?.data?.code === 200) {
                let { data } = res.data;
                console.log('data in updateVendorDetails', data);
                toast.success('Details Updated');
                // dispatch({ type: GET_VENDOR_DETAILS, payload: data });
                return data;
            } else {
                toast.error('Something Went Worng!');
            }
        } catch (error) {
            dispatch({ type: LOADING_FAILURE });
        }
    };
