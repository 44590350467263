import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { applicantInformation, coApplicantInformation, uploadFormData } from '../../redux/Actions/FinanceFormActions';
import { checkValidator } from './validate';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ROUTES, RoleContext, _getVendorId } from '../../services';
import '../index.css';
import { RootState } from '../../redux/Reducers';
import { APPLICANT_ROUTES, CO_APPLICANT_ROUTES } from '../../services/applicationConstants';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { validateUrl } from '../../services/helperFunctionServices';

export default function Index(props: any) {
    const dispatch = useDispatch();
    const { financeForm, vendorInfo, isCoApplicant } = useSelector((state: RootState) => {
        return state.FinanceFormReducer;
    });
    const _vendorID = _getVendorId(props);
    const { role } = props.match.params;
    // const role = useContext(RoleContext);
    const [state, setState] = useState<any>({ income: '' });
    const [error, setError] = useState<any>({});
    const [isDisclaimerChecked, setDisclaimer] = useState<boolean>(false);
    useEffect(() => {
        if (
            props.match.params.role === 'applicant' &&
            financeForm.applicant &&
            financeForm.applicant.incomeInformation
        ) {
            setState(financeForm.applicant.incomeInformation);
        } else if (
            props.match.params.role === 'co-applicant' &&
            financeForm.coApplicant &&
            financeForm.coApplicant.incomeInformation
        ) {
            setState(financeForm.coApplicant.incomeInformation);
        }
    }, []);

    const handleDisclaimer = () => {
        setDisclaimer(!isDisclaimerChecked);
    };
    const onSubmit = async (event: any) => {
        event.preventDefault();
        const validateResponse = await checkValidator(state);
        console.log({ vendorInfo });
        console.log('state', validateResponse, role);
        if (validateResponse?.isFormValid) {
            setError({});
            if (role === 'applicant') {
                dispatch(applicantInformation({ incomeInformation: state, _vendorID }, 5));
                props.history.push(APPLICANT_ROUTES.WelcomePage + _vendorID);
            } else {
                await dispatch(coApplicantInformation({ incomeInformation: state, _vendorID }, 5));
                // Write redirection logic here
                financeForm['isCoApplicant'] = isCoApplicant;
                financeForm['_vendorID'] = _vendorID;
                financeForm['coApplicant']['incomeInformation'] = state;
                await dispatch(uploadFormData(financeForm, vendorInfo, props));
            }
        } else setError(validateResponse.error);
    };
    const handleChange = (event: any, type: string, buttonName?: string | undefined) => {
        event.preventDefault();
        let { name, value } = event.target;
        // console.log("name, value", name, "d", value);
        if (type === 'checkbox') {
            setState((prevState: any) => ({
                ...prevState,
                [name]: event.target.checked,
            }));
        } else if (type === 'button') {
            buttonName && setState((prevState: any) => ({ ...prevState, [buttonName]: value }));
        } else setState((prevState: any) => ({ ...prevState, [name]: value }));
    };
    return (
        <>
            {vendorInfo && vendorInfo?.isActive === 1 ? (
                <div className="loanForm">
                    <h3>Income Information</h3>
                    <a href={vendorInfo?.websiteUniqueUrl} hidden />
                    <form className="d-flex commnform">
                        <div className="form-group fullline">
                            <label>
                                Gross Income <sup>*</sup>
                            </label>

                            <input
                                className="form-control"
                                type="number"
                                placeholder="Gross Income"
                                name="income"
                                max={10}
                                value={`${state?.income}`}
                                onChange={(event: any) => handleChange(event, 'text')}
                            />
                            <div className="error">{error.income ?? error.income}</div>
                        </div>
                        <div className="form-group btnM0 fullline">
                            <div>
                                <label>
                                    Income Period <sup>*</sup>
                                </label>
                            </div>
                            <div className="fullwidth">
                                <button
                                    value="Weekly"
                                    name="incomePeriod"
                                    className={
                                        state?.incomePeriod === 'Weekly'
                                            ? 'form-control creditbtn buttonActive'
                                            : 'form-control creditbtn'
                                    }
                                    onClick={(e) => {
                                        handleChange(e, 'button', 'incomePeriod');
                                    }}
                                >
                                    Weekly
                                </button>
                                <button
                                    className={
                                        state?.incomePeriod === 'Monthly'
                                            ? 'form-control creditbtn buttonActive'
                                            : 'form-control creditbtn'
                                    }
                                    value="Monthly"
                                    onClick={(e) => {
                                        handleChange(e, 'button', 'incomePeriod');
                                    }}
                                >
                                    Monthly
                                </button>
                                <button
                                    className={
                                        state?.incomePeriod === 'Yearly'
                                            ? 'form-control creditbtn buttonActive'
                                            : 'form-control creditbtn'
                                    }
                                    value="Yearly"
                                    name="incomePeriod"
                                    onClick={(e) => {
                                        handleChange(e, 'button', 'incomePeriod');
                                    }}
                                >
                                    Yearly
                                </button>
                            </div>
                            <div className="error">{error.incomePeriod ?? error.incomePeriod}</div>
                        </div>
                        <div className="form-group fullline">
                            <label>Other Income</label>
                            <input
                                className="form-control"
                                type="number"
                                placeholder="Other Income"
                                name="otherIncome"
                                max={10}
                                value={state?.otherIncome}
                                onChange={(event: any) => handleChange(event, 'text')}
                            />
                        </div>
                        <div className="form-group fullline btnM0">
                            <div>
                                <label>Other Income Period</label>
                            </div>
                            <div className="fullwidth">
                                <button
                                    value="Weekly"
                                    name="otherIncomePeriod"
                                    className={
                                        state?.otherIncomePeriod === 'Weekly'
                                            ? 'form-control creditbtn buttonActive'
                                            : 'form-control creditbtn'
                                    }
                                    onClick={(e) => {
                                        handleChange(e, 'button', 'otherIncomePeriod');
                                    }}
                                >
                                    Weekly
                                </button>
                                <button
                                    className={
                                        state?.otherIncomePeriod === 'Monthly'
                                            ? 'form-control creditbtn buttonActive'
                                            : 'form-control creditbtn'
                                    }
                                    value="Monthly"
                                    onClick={(e) => {
                                        handleChange(e, 'button', 'otherIncomePeriod');
                                    }}
                                >
                                    Monthly
                                </button>
                                <button
                                    className={
                                        state?.otherIncomePeriod === 'Yearly'
                                            ? 'form-control creditbtn buttonActive'
                                            : 'form-control creditbtn'
                                    }
                                    value="Yearly"
                                    name="otherIncomePeriod"
                                    onClick={(e) => {
                                        handleChange(e, 'button', 'otherIncomePeriod');
                                    }}
                                >
                                    Yearly
                                </button>
                            </div>
                            <div className="error">{error.otherIncomePeriod ?? error.otherIncomePeriod}</div>
                        </div>
                        <div className="form-group fullline">
                            <label>Other Income Source</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Other Income Source"
                                name="otherIncomeSource"
                                value={state?.otherIncomeSource}
                                max={50}
                                onChange={(event: any) => handleChange(event, 'text')}
                            />
                            <div className="error">{error.otherIncomeSource ?? error.otherIncomeSource}</div>
                        </div>

                        <div>
                            <label style={{ textAlign: 'left' }}>
                                <input type="checkbox" checked={isDisclaimerChecked} onChange={handleDisclaimer} />
                                Disclaimer: I understand that by checking this box I am providing 'written instructions'
                                to Special Financing LLC under the Fair Credit Reporting Act, authorizing Special
                                Financing LLC to obtain information from my personal credit profile. I authorize Special
                                Financing LLC to obtain such information solely to conduct a pre-qualification for
                                credit.
                            </label>
                        </div>

                        <div className="absArrws">
                            <div>
                                <button
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        props.history.goBack();
                                    }}
                                    className="arrBtn arrowBackward"
                                >
                                    <ArrowBackIosIcon />
                                </button>
                            </div>
                            <div>
                                <button
                                    onClick={onSubmit}
                                    disabled={!isDisclaimerChecked}
                                    style={{
                                        background: isDisclaimerChecked ? '#48A6EA' : '#5e6162',
                                        color: '#fff',
                                        // border: '1px solid #48A6EA',
                                        borderRadius: 5,
                                        padding: '6px 12px',
                                    }}
                                >
                                    Submit
                                    {/* <ArrowForwardIosIcon /> */}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            ) : (
                <h3 className="loanForm inactiveVendor">Inactive Vendor</h3>
            )}
        </>
    );
}
