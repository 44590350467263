import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../services/index';
import { Menu, Close, ArrowRight, PowerSettingsNew, Dashboard } from '@material-ui/icons';
import './style.css';
import { withRouter } from 'react-router-dom';
import { LogOutAction } from '../../redux/Actions/AuthAction';
import { useDispatch } from 'react-redux';
function Index(props: any) {
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);

    return (
        <div className={open ? 'openside adminSidenav sidenav' : 'notopenside adminSidenav sidenav'}>
            <button
                className="toggleNav MenuIcon"
                onClick={() => {
                    setOpen(!open);
                }}
            >
                <Menu />
            </button>
            <button
                className="toggleNav CloseIcon"
                onClick={() => {
                    setOpen(!open);
                }}
            >
                <Close />
            </button>
            <div className="sideInNav">
                <div className={open ? 'open' : 'notopen'}>
                    <h2 className="siteNameNav">Vendor Dashboard</h2>
                    <nav>
                        <ul className="navList">
                            <li>
                                <Link to={ROUTES.Dashboard}>
                                    <Dashboard /> &nbsp;Transactions{' '}
                                    <span>
                                        <ArrowRight />
                                    </span>{' '}
                                </Link>
                            </li>
                            <li>
                                <Link to={`${ROUTES.EditVendor}`}>
                                    <Dashboard /> &nbsp;Edit Details{' '}
                                    <span>
                                        <ArrowRight />
                                    </span>{' '}
                                </Link>
                            </li>
                            <li>
                                <a
                                    onClick={() => {
                                        dispatch(LogOutAction(props));
                                    }}
                                >
                                    <PowerSettingsNew /> &nbsp;Logout
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
}
export default withRouter(Index);
