interface FormIProps {
  newPassword: string | undefined;
  confirmPassword: string | undefined;
}

export function validateLogIn(formData: FormIProps | undefined) {
  console.log("formData", formData);
  let isFormValid = true;
  let error: FormIProps = {
    newPassword: "",
    confirmPassword:""
  };

  if (formData == undefined) {
    isFormValid = false;
    error.newPassword = "This field cannot be empty!"
    error.confirmPassword = "This field cannot be empty!"
  }
  if (
    formData &&
    (formData?.newPassword == undefined ||
      formData?.newPassword == "" ||
      formData?.newPassword.trim().length == 0)
  ) {
    isFormValid = false;
    error.newPassword = "This field cannot be empty!";
  } 
  if (
    formData &&
    (formData?.confirmPassword == undefined ||
      formData?.confirmPassword == "" ||
      formData?.confirmPassword.trim().length == 0)
  ) {
    isFormValid = false;
    error.confirmPassword = "This field cannot be empty!";
  } 
  if (
    formData &&
    (formData?.confirmPassword !== formData?.newPassword)
  ) {
    isFormValid = false;
    error.confirmPassword = "Password do not match!";
  } 
  
  console.log(error, "LOGIN FROM ERR");
  return {
    isFormValid,
    error,
  };
  // }
}
