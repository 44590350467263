import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkValidator } from './validate';
import {
    applicantInformation,
    coApplicantInformation,
    _getVendorDetails,
} from '../../redux/Actions/FinanceFormActions';
import '../index.css';
import { ROUTES, RoleContext } from '../../services';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { RootState } from '../../redux/Reducers';
import { APPLICANT_ROUTES, CO_APPLICANT_ROUTES, _getVendorId } from '../../services';

export default function Index(props: any) {
    const dispatch = useDispatch();
    const _vendorID = _getVendorId(props);
    console.log('_vendorID in ', _vendorID);
    const { role } = props.match.params;

    const [state, setState] = useState<any>();
    const [error, setError] = useState<any>({});
    const { financeForm, vendorInfo } = useSelector((state: RootState) => {
        return state.FinanceFormReducer;
    });

    useEffect(() => {
        dispatch(_getVendorDetails(_vendorID));

        if (role === 'applicant' && financeForm.applicant && financeForm.applicant.personalInformation) {
            setState(financeForm.applicant.personalInformation);
        } else if (role === 'co-applicant' && financeForm.coApplicant && financeForm.coApplicant.personalInformation) {
            setState(financeForm.coApplicant.personalInformation);
        }
    }, []);
    function getTodayDate() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        return yyyy + '-' + mm + '-' + dd;
    }
    function formatPhoneNumber(phone: string) {
        phone = phone.replace(/\D/g, '');
        const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
        if (match) {
            phone = `${match[1]}${match[2] ? '-' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}`;
        }
        return phone;
    }
    function formatSSN(phone: string) {
        phone = phone.replace(/\D/g, '');
        const match = phone.match(/^(\d{1,3})(\d{0,2})(\d{0,4})$/);
        if (match) {
            phone = `${match[1]}${match[2] ? '-' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}`;
        }
        return phone;
    }

    const handleChange = (event: any, type: string) => {
        console.log('AAA');
        event.preventDefault();

        let { name, value } = event.target;
        if (type === 'checkbox') {
            setState((prevState: any) => ({
                ...prevState,
                [name]: event.target.checked,
            }));
        } else if (type === 'dashNumber') {
            setState((prevState: any) => ({
                ...prevState,
                [name]: formatPhoneNumber(value),
            }));
        } else if (type === 'ssnNumber') {
            setState((prevState: any) => ({ ...prevState, [name]: formatSSN(value) }));
        } else if (type === 'button') {
            setState((prevState: any) => ({ ...prevState, creditScore: value }));
        } else setState((prevState: any) => ({ ...prevState, [name]: value }));
    };
    const onSubmit = async (event: any) => {
        event.preventDefault();
        const validateResponse = await checkValidator(state);
        console.log('p validateResponse', validateResponse);
        if (validateResponse?.isFormValid) {
            setError({});
            if (role === 'applicant') {
                dispatch(applicantInformation({ personalInformation: state }, 2));
                props.history.push(APPLICANT_ROUTES.AddressInformation + _vendorID);
            } else if (role === 'co-applicant') {
                dispatch(coApplicantInformation({ personalInformation: state }, 2));
                props.history.push(CO_APPLICANT_ROUTES.AddressInformation + _vendorID);
            }
        } else setError(validateResponse.error);
    };

    return (
        <>
            {vendorInfo && vendorInfo?.isActive === 1 ? (
                <div className="loanForm ">
                    <h3>Personal Information</h3>
                    <form className="d-flex commnform">
                        <div className="form-group nameDiv">
                            <label>
                                First Name <sup>*</sup>
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="First Name"
                                name="firstName"
                                value={state?.firstName}
                                onChange={(event: any) => handleChange(event, 'text')}
                                maxLength={50}
                            />
                            <div className="error">{error.firstName ?? error.firstName}</div>
                        </div>
                        <div className="form-group nameDiv">
                            <label>Mid Name</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Mid Name"
                                name="midName"
                                value={state?.midName}
                                maxLength={1}
                                onChange={(event: any) => handleChange(event, 'text')}
                            />
                            <div className="error">{error.midName ?? error.midName}</div>
                        </div>
                        <div className="form-group nameDiv">
                            <label>
                                Last Name <sup>*</sup>
                            </label>
                            <input
                                className="form-control"
                                type="input"
                                placeholder="Last Name"
                                name="lastName"
                                value={state?.lastName}
                                maxLength={50}
                                onChange={(event: any) => handleChange(event, 'text')}
                            />
                            <div className="error">{error.lastName ?? error.lastName}</div>
                        </div>
                        <div className="form-group">
                            <label>
                                Social Security Number <sup>*</sup>
                            </label>
                            <input
                                className="form-control"
                                type="tel"
                                placeholder="Social Security Number"
                                name="ssn"
                                value={state?.ssn}
                                maxLength={11}
                                minLength={11}
                                onChange={(event: any) => handleChange(event, 'ssnNumber')}
                            />
                            <div className="error">{error.ssn ?? error.ssn}</div>
                        </div>
                        <div className="form-group">
                            <label>
                                DOB <sup>*</sup>
                            </label>
                            <input
                                className="form-control"
                                type="date"
                                placeholder="MM/DD/YYYY"
                                value={state?.dob}
                                name="dob"
                                min="1930-01-01"
                                max={getTodayDate()}
                                onChange={(event: any) => handleChange(event, 'text')}
                            />
                            {console.log(getTodayDate())}
                            <div className="error">{error.dob ?? error.dob}</div>
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input
                                className="form-control"
                                type="email"
                                placeholder="Email"
                                name="email"
                                value={state?.email}
                                onChange={(event: any) => handleChange(event, 'text')}
                            />
                            <div className="error">{error.email ?? error.email}</div>
                        </div>
                        <div className="form-group">
                            <label>
                                Home Phone No <sup>*</sup>
                            </label>
                            <input
                                className="form-control"
                                placeholder="Home Phone No"
                                value={state?.homPhone}
                                name="homPhone"
                                type="tel"
                                maxLength={12}
                                onChange={(event: any) => handleChange(event, 'dashNumber')}
                            />
                            <div className="error">{error.homPhone ?? error.homPhone}</div>
                        </div>
                        <div className="form-group">
                            <label>Work Phone No</label>
                            <input
                                className="form-control"
                                placeholder="Business Phone No"
                                value={state?.busPhone}
                                name="busPhone"
                                // onChange={(event: any) => handleChange(event, "text")}
                                type="tel"
                                maxLength={12}
                                onChange={(event: any) => handleChange(event, 'dashNumber')}
                            />
                            <div className="error">{error.busPhone ?? error.busPhone}</div>
                        </div>
                        <div className="form-group">
                            <label>Mobile Phone No</label>
                            <input
                                className="form-control"
                                type="tel"
                                placeholder="Mobile Phone No"
                                value={state?.celPhone}
                                name="celPhone"
                                maxLength={12}
                                onChange={(event: any) => handleChange(event, 'dashNumber')}
                            />
                            <div className="error">{error.celPhone ?? error.celPhone}</div>
                        </div>
                        <div className="form-group">
                            <label>
                                How Much Money Do You Want to Request? <sup>*</sup>
                            </label>
                            <input
                                className="form-control"
                                type="number"
                                value={state?.moneyRequested}
                                name="moneyRequested"
                                maxLength={12}
                                onChange={(event: any) => handleChange(event, 'text')}
                            />
                            <div className="error">{error.moneyRequested ?? error.moneyRequested}</div>
                        </div>

                        <div className="absArrws">
                            <div>
                                {/* <button
                   onClick={(e: any) => {
                     e.preventDefault();
                     props.history.goBack();
                   }}
                   className="arrBtn"
                 >
                   <ArrowBackIosIcon />
                 </button> */}
                            </div>
                            <div>
                                <button onClick={onSubmit} className="arrBtn">
                                    <ArrowForwardIosIcon />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            ) : (
                <h3 className="loanForm inactiveVendor">Inactive Vendor</h3>
            )}
        </>
    );
}
