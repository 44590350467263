import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';

export default function BasicTable({ data, onClickViewDetails }: any) {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(data.count / 2); i++) {
        pageNumbers.push(i);
    }
    return (
        <TableContainer className="tableParent" component={Paper}>
            <Table className="table" aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>S.No.</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Transaction Status</TableCell>
                        <TableCell>API Request</TableCell>
                        <TableCell>API Response</TableCell>
                
                        {/* <TableCell>Transaction Data</TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data && data.data && data.data.length > 0 ? (
                        data.data.map((row: any, i: number) => {
                            // const personalInformation = row?.applicantsId?.applicant?.personalInformation;
                            return (
                                <TableRow key={row?.name}>
                                    <TableCell component="th" scope="row">
                                        {String(i + 1)}
                                    </TableCell>
                                    <TableCell>
                                        {row?.firstName ? row?.firstName + ' ' : '-'}
                                        {row?.lastName ? row?.lastName + ' ' : ''}
                                    </TableCell>
                                    <TableCell>{row?.email ? row?.email : '-'}</TableCell>
                                    <TableCell>
                                        {row?.createdAt ? moment(row?.createdAt).format('MMMM Do YYYY') : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {row.specialFinanceApiStatus == true ? (
                                            <p style={{ color: 'green' }}>Success</p>
                                        ) : (
                                            <p style={{ color: 'red' }}>Fail</p>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <button
                                            onClick={() => {
                                                onClickViewDetails(row.applicationNum,"request");
                                            }}
                                            className="btn buttonActive"
                                        >
                                            View
                                        </button>
                                    </TableCell> <TableCell>
                                        <button
                                            onClick={() => {
                                                onClickViewDetails(row.applicationNum);
                                            }}
                                            className="btn buttonActive"
                                        >
                                            View
                                        </button>
                                    </TableCell>
                                    {/* <TableCell>
                                        <button
                                            onClick={() => {
                                                onClickViewDetails(row.applicationNum, "transaction");
                                            }}
                                            className="btn buttonActive"
                                        >
                                            View
                                        </button>
                                    </TableCell> */}
                                </TableRow>
                            );
                        })
                    ) : (
                        <TableRow>No Items to show</TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
