function containSpecialCharacter(str:any) {
    var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;                      
  return format.test(str);
  }
export const checkValidator = (formData: any) => {
    let isFormValid: boolean = true;
    let error: any = {
        income: '',
        otherIncomeSource:""
    };
    //   console.log("formData", formData);
    if (formData === undefined) {
        error = {
            income: 'This field is required.',
            incomePeriod: 'This field is required.',
        };
        isFormValid = false;
    }
    if (formData && (formData?.income == undefined || formData?.income == '' || formData?.income.trim().length == 0)) {
        isFormValid = false;
        error.income = 'This Field cannot be empty!';
    }
 else if (formData && formData?.income && (formData?.income.length > 10 || formData?.income.length < 0)) {
        isFormValid = false;
        error.income = 'Gross Income must be less than 10 digits.';
    }
    if(containSpecialCharacter(formData?.income)){
        isFormValid = false;
        error.income = "Please remove special characters";
      }
    if(containSpecialCharacter(formData?.otherIncomeSource)){
        isFormValid = false;
        error.otherIncomeSource = "Please remove special characters";
      }
    if (
        formData &&
        (formData?.incomePeriod == undefined ||
            formData?.incomePeriod == '' ||
            formData?.incomePeriod.trim().length == 0)
    ) {
        isFormValid = false;
        error.incomePeriod = 'This Field cannot be empty!';
    }
    if (formData && formData?.otherIncome && formData?.otherIncomePeriod == undefined) {
        isFormValid = false;
        error.otherIncomePeriod = 'This Field cannot be empty!';
    }
    return {
        isFormValid,
        error,
    };
};
